export const state = () => ({
  name: null,
  email: null,
  phone: null,
  phoneCountryCode: null,
});

export const getters = {};

export const mutations = {
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
  SET_PHONE_COUNTRY_CODE(state, payload) {
    state.phoneCountryCode = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.name = null;
    state.email = null;
    state.phone = null;
    state.phoneCountryCode = null;
  },
};

export const actions = {
  async sendContactRequest({ state }, listingUuid) {
    let formData = {
      name: state.name,
      email: state.email,
      phone_number: state.phone,
      listing_uuid: listingUuid,
      phone_country_code: state.phoneCountryCode,
    };
    try {
      const response = await this.$http.post('/api/v2/contact_request/post_request', formData);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
};
