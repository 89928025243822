import xss from 'xss';
import formatDate from 'date-fns/format';
import parseDateISO from 'date-fns/parseISO';

export const state = () => ({
  rentTypeList: [],
  tenureTypeList: [],
  selectedTenureType: null,
  sizeInSquareMeters: '',
  landSize: '',
  occupied: false,
  sizeInSquareFoot: '',
  sellPrice: null,
  developerName: null,
  rentPeriodList: [],
  rentPeriodRules: null,
  rentTypeRules: null,
  propertyTypeCategoryAllowTenureRule: null,
  isDone: true,
  isDiscPercentage: true,
  discountPercentage: null,
  discountPrice: null,
  showDiscount: false,
  doneYearEst: null,
  availableFrom: null,
  isNegotiable: {
    allowed: true,
    selected: true,
  },
  defaultValue: {},
  isTouched: false,
  covidProtocol: null,
  covidStatus: null,
  covidStatuses: [],
  useProtocol: false,
  viewToOffer: false,
  isConditionPrimary: true,
  minRentDuration: null,
  bookingFeeMonthAmount: null,
});

export const getters = {
  isNegotiableAllowed(state) {
    return state.isNegotiable.allowed;
  },
  defaultFormValue(state) {
    return JSON.parse(JSON.stringify(state.defaultValue));
  },
  currentFormValue(state) {
    return JSON.parse(
      JSON.stringify({
        rentTypeList: state.rentTypeList,
        tenureTypeList: state.tenureTypeList,
        sizeInSquareMeters: state.sizeInSquareMeters,
        landSize: state.landSize,
        occupied: state.occupied,
        selectedTenureType: state.selectedTenureType,
        sizeInSquareFoot: '',
        rentPeriodList: state.rentPeriodList,
        isNegotiable: state.isNegotiable,
        developerName: state.developerName,
        isConditionPrimary: state.isConditionPrimary,
        isDone: state.isDone,
        doneYearEst: state.doneYearEst,
        minRentDuration: state.minRentDuration,
        bookingFeeMonthAmount: state.bookingFeeMonthAmount,
      }),
    );
  },
  selectedRentTypeId(state) {
    let selectedRentType = state.rentTypeList.find(function(rentType) {
      return rentType.selected;
    });
    if (selectedRentType) {
      return selectedRentType.id;
    }
    return null;
  },
  selectedRentTypeText(state) {
    let selectedRentType = state.rentTypeList.find(function(rentType) {
      return rentType.selected;
    });
    if (selectedRentType) {
      return selectedRentType.text;
    }
    return null;
  },

  rentTypeOptionsId(state) {
    return state.rentTypeList
      .filter(function(item) {
        return item.allowed;
      })
      .map(function(item) {
        return item.id;
      });
  },
  rentTypeOptionLabel: state => rentTypeId => {
    const rentType = state.rentTypeList.find(function(rentType) {
      return rentType.id == rentTypeId;
    });
    return rentType.text;
  },
  rentPeriodList(state) {
    return state.rentPeriodList.filter(function(rentPeriod) {
      return rentPeriod.allowed;
    });
  },
  selectedRentPeriodIds(state) {
    let selectedList = state.rentPeriodList.filter(function(rentPeriod) {
      return rentPeriod.checked && rentPeriod.allowed;
    });
    return selectedList.map(function(rp) {
      return rp.id;
    });
  },
  selectedRentPrices(state) {
    return state.rentPeriodList.filter(function(rentPeriod) {
      return rentPeriod.checked && rentPeriod.allowed;
    });
  },
  // eslint-disable-next-line no-unused-vars
  allowTenure(state, getters, rootState, rootGetters) {
    const selectedPropertyTypeCategoryId =
      rootGetters['v2/listingForm/sect1/location/selectedPropertyTypeCategory'];
    if (!selectedPropertyTypeCategoryId) return false;
    return !!state.propertyTypeCategoryAllowTenureRule.find(rule => {
      return rule.property_type_category_id == selectedPropertyTypeCategoryId;
    });
  },
  allRentPrice(state, getters) {
    let rentPeriodList = getters.rentPeriodList;
    return rentPeriodList.map(function(rp) {
      return rp.price;
    });
  },
  allRentPriceFormData(state) {
    let rentPeriodList = JSON.parse(JSON.stringify(state.rentPeriodList));
    let selectedList = rentPeriodList.filter(function(rentPeriod) {
      return rentPeriod.allowed;
    });
    return selectedList.map(function(rp) {
      return rp.price;
    });
  },
  rentPrice: state => rentPeriodId => {
    return state.rentPeriodList.find(function(rentPeriod) {
      return rentPeriod.id == rentPeriodId;
    }).price;
  },

  formData(state, getters, rootState, rootGetters) {
    try {
      return {
        rent_type_id: getters.selectedRentTypeId,
        size_in_square_meters: state.sizeInSquareMeters,
        land_size: state.landSize,
        occupied: state.occupied,
        tenure_type_id: state.selectedTenureType ? state.selectedTenureType.id : null,
        developer_name: state.developerName,
        size_in_square_foot: state.sizeInSquareFoot,
        rent_period_id: getters.selectedRentPeriodIds,
        price: getters.allRentPriceFormData,
        sell_price: state.sellPrice,
        view_to_offer: state.viewToOffer,
        min_rent_duration: state.minRentDuration,
        booking_fee_month_amount: state.bookingFeeMonthAmount,
        available_from: state.availableFrom ? formatDate(state.availableFrom, 'dd-MM-yyyy') : null,
        discount_percentage: rootGetters['global/allowListingDiscount']
          ? state.discountPercentage
          : null,
        discount_price: rootGetters['global/allowListingDiscount'] ? state.discountPrice : null,
        is_done: state.isDone,
        done_year_est: state.doneYearEst,
        is_condition_primary: state.isConditionPrimary,
        is_negotiable: getters.isNegotiableValue,
        covid_status_id: state.useProtocol && state.covidStatus ? state.covidStatus.id : null,
        covid_protocol: state.useProtocol
          ? xss(state.covidProtocol, {
              whiteList: {
                a: ['href', 'title', 'target'],
                br: [],
              },
            })
          : null,
      };
    } catch (e) {
      console.log('errrorr', e);
    }
  },

  isNegotiableValue(state) {
    return state.isNegotiable.selected ? 1 : 0;
  },
};

export const mutations = {
  SET_AVAILABLE_FROM(state, payload) {
    state.availableFrom = payload;
  },
  SET_IS_DONE(state, payload) {
    state.isDone = payload;
  },
  SET_OCCUPIED(state, payload) {
    state.occupied = payload;
  },
  SET_MIN_RENT_DURATION(state, payload) {
    state.minRentDuration = payload;
  },
  SET_BOOKING_FEE_MONTH_AMOUNT(state, payload) {
    state.bookingFeeMonthAmount = payload;
  },
  SET_VIEW_TO_OFFER(state, payload) {
    state.viewToOffer = payload;
  },
  SET_SHOW_DISCOUNT(state, payload) {
    state.showDiscount = payload;
  },
  SET_CATEGORY_ALLOW_TENURE_RULE(state, payload) {
    state.propertyTypeCategoryAllowTenureRule = payload;
  },
  SET_CONDITION_PRIMARY(state, payload) {
    state.isConditionPrimary = payload;
  },
  SET_IS_DISC_PERCENTAGE(state, payload) {
    state.isDiscPercentage = payload;
  },
  SET_DONE_YEAR_EST(state, payload) {
    state.doneYearEst = payload;
  },
  SET_RENT_PERIOD_RULES(state, payload) {
    state.rentPeriodRules = payload;
  },
  SET_DEVELOPER_NAME(state, payload) {
    state.developerName = payload;
  },
  SET_DISCOUNT_PERCENTAGE(state, payload) {
    state.discountPercentage = payload;
  },
  SET_DISCOUNT_PRICE(state, payload) {
    state.discountPrice = payload;
  },
  SET_SELL_PRICE(state, payload) {
    state.sellPrice = payload;
  },
  SET_RENT_TYPE_RULES(state, payload) {
    state.rentTypeRules = payload;
  },
  SET_IS_TOUCHED(state, payload) {
    state.isTouched = payload;
  },
  SET_COVID_STATUS(state, payload) {
    state.covidStatus = payload;
  },
  SET_COVID_STATUSES(state, payload) {
    state.covidStatuses = payload;
  },
  SET_COVID_PROTOCOL(state, payload) {
    state.covidProtocol = payload;
  },
  SET_USE_PROTOCOL(state, payload) {
    state.useProtocol = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.rentTypeList = [];
    state.sizeInSquareMeters = '';
    state.landSize = '';
    state.selectedTenureType = null;
    state.sizeInSquareFoot = '';
    state.occupied = false;
    state.rentPeriodRules = null;
    state.minRentDuration = null;
    state.bookingFeeMonthAmount = null;
    state.developerName = null;
    state.sellPrice = null;
    state.viewToOffer = false;
    state.availableFrom = null;
    state.discountPercentage = null;
    state.discountPrice = null;
    state.rentTypeRules = null;
    state.doneYearEst = null;
    state.isConditionPrimary = true;
    state.isDone = true;
    state.showDiscount = false;
    state.isDiscPercentage = true;
    state.rentPeriodList = [];
    state.isNegotiable = {
      allowed: true,
      selected: true,
    };
    state.isTouched = false;
    state.covidStatuses = [];
    state.covidStatus = null;
    state.covidProtocol = null;
    state.useProtocol = false;
  },
  SHOW_ALLOWED_NEGO(state, allowed) {
    state.isNegotiable.allowed = allowed;
  },
  SHOW_ALLOWED_RENT_TYPE(state, allowedIds) {
    if (Object.keys(state.rentTypeList).length > 0) {
      for (let i = 0; i < state.rentTypeList.length; i++) {
        if (allowedIds.indexOf(state.rentTypeList[i].id) !== -1) {
          state.rentTypeList[i].allowed = true;
        } else {
          state.rentTypeList[i].allowed = false;
        }
      }
    }
  },
  SHOW_ALLOWED_RENT_PERIOD(state, allowedIds) {
    if (Object.keys(state.rentPeriodList).length > 0) {
      for (let i = 0; i < state.rentPeriodList.length; i++) {
        state.rentPeriodList[i].allowed = allowedIds.indexOf(state.rentPeriodList[i].id) !== -1;
      }
    }
  },
  CHANGE_ISNEGOTIABLE(state, newVal) {
    state.isNegotiable.selected = newVal == 1;
  },
  CHANGE_SIZEINSQUAREMETERS(state, newVal) {
    state.sizeInSquareMeters = newVal;
  },

  SET_LAND_SIZE(state, newVal) {
    state.landSize = newVal;
  },

  SET_SELECTED_TENURE_TYPE(state, newVal) {
    state.selectedTenureType = newVal;
  },

  CHANGE_SIZEINSQUAREFOOT(state, newVal) {
    state.sizeInSquareFoot = newVal;
  },
  CHANGE_PRICE(state, { rentPeriodId, price }) {
    const rentPeriod = state.rentPeriodList.find(function(rp) {
      return rp.id == rentPeriodId;
    });
    rentPeriod.price = price;
  },
  TOGGLE_CHECK_RENT_PERIOD(state, rentPeriodId) {
    const rentPeriod = state.rentPeriodList.find(function(rp) {
      return rp.id == rentPeriodId;
    });

    rentPeriod.checked = !rentPeriod.checked;
    if (!rentPeriod.checked) {
      rentPeriod.price = '';
    }
  },

  SET_RENT_PERIOD_LIST(state, payload) {
    payload.forEach(function(element, index_, arr_) {
      arr_[index_].checked = false;
      arr_[index_].allowed = true;
      arr_[index_].price = '';
    });
    state.rentPeriodList = payload;
  },

  SET_TENURE_TYPE_LIST(state, payload) {
    state.tenureTypeList = payload;
  },

  SET_RENT_TYPE_LIST(state, payload) {
    payload.forEach(function(element, index_, arr_) {
      arr_[index_].text = arr_[index_].name;
      delete arr_[index_].name;
      arr_[index_].selected = false;
      arr_[index_].allowed = true;
    });
    state.rentTypeList = payload;
  },
  SET_SELECTED_RENT_TYPE(state, rentTypeId) {
    state.rentTypeList.forEach(function(rentType, index_, arr_) {
      if (rentType.id == rentTypeId) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_DEFAULT_VALUE(state) {
    state.defaultValue = JSON.parse(
      JSON.stringify({
        rentTypeList: state.rentTypeList,
        sizeInSquareMeters: state.sizeInSquareMeters,
        landSize: state.landSize,
        occupied: state.occupied,
        viewToOffer: state.viewToOffer,
        selectedTenureType: state.selectedTenureType,
        developerName: state.developerName,
        sizeInSquareFoot: state.sizeInSquareFoot,
        rentPeriodList: state.rentPeriodList,
        isNegotiable: state.isNegotiable,
        isConditionPrimary: state.isConditionPrimary,
        isDone: state.isDone,
        minRentDuration: state.minRentDuration,
        bookingFeeMonthAmount: state.bookingFeeMonthAmount,
        doneYearEst: state.doneYearEst,
      }),
    );
  },
  USE_DEFAULT_VALUE(state) {
    const defaultValue = JSON.parse(JSON.stringify(state.defaultValue));
    state.rentTypeList = defaultValue.rentTypeList;
    state.sizeInSquareMeters = defaultValue.sizeInSquareMeters;
    state.landSize = defaultValue.landSize;
    state.occupied = defaultValue.occupied;
    state.minRentDuration = defaultValue.minRentDuration;
    state.bookingFeeMonthAmount = defaultValue.bookingFeeMonthAmount;
    state.viewToOffer = defaultValue.viewToOffer;
    state.selectedTenureType = defaultValue.selectedTenureType;
    state.developerName = defaultValue.developerName;
    state.sizeInSquareFoot = defaultValue.sizeInSquareFoot;
    state.rentPeriodList = defaultValue.rentPeriodList;
    state.isNegotiable = defaultValue.isNegotiable;
    state.isConditionPrimary = defaultValue.isConditionPrimary;
    state.isDone = defaultValue.isDone;
    state.doneYearEst = defaultValue.doneYearEst;
  },
};

export const actions = {
  applyRentTypeRule({ commit, state }, propertyTypeId) {
    const allowedRules = state.rentTypeRules
      .filter(rentType => {
        return rentType.property_type_id === propertyTypeId;
      })
      .map(rentType => {
        return rentType.rent_type_id;
      });
    commit('SHOW_ALLOWED_RENT_TYPE', allowedRules);
  },
  applyRentPeriodRule({ commit, state }, propertyTypeId) {
    const allowedRules = state.rentPeriodRules
      .filter(rentType => {
        return rentType.property_type_id === propertyTypeId;
      })
      .map(rentType => {
        return rentType.rent_period_id;
      });
    commit('SHOW_ALLOWED_RENT_PERIOD', allowedRules);
  },

  applyDefaultValue({ commit }) {
    commit('USE_DEFAULT_VALUE');
    commit('SET_IS_TOUCHED', false);
  },
  setDefaultValue({ commit, getters }) {
    const data = getters.currentFormValue;
    commit('SET_DEFAULT_VALUE', data);
  },
  applyBaseData({ commit }, { data }) {
    commit('SET_RENT_PERIOD_LIST', data.rentPeriods);
    commit('SET_RENT_TYPE_LIST', data.rentTypes);
    commit('SET_RENT_PERIOD_RULES', data.rentPeriodRule);
    commit('SET_RENT_TYPE_RULES', data.rentTypeRule);
    commit('SET_TENURE_TYPE_LIST', data.tenureTypes);
    commit('SET_CATEGORY_ALLOW_TENURE_RULE', data.propertyTypeCategoryAllowTenureRule);
    commit('SET_COVID_STATUSES', data.covidStatuses);
  },
  applyEditData({ commit, state }, { data }) {
    try {
      commit('CHANGE_SIZEINSQUAREMETERS', data.size_in_square_meters);
      commit('SET_LAND_SIZE', data.land_size);
      commit('SET_OCCUPIED', data.occupied);
      commit('SET_DEVELOPER_NAME', data.developer_name);
      commit('CHANGE_SIZEINSQUAREFOOT', data.size_in_square_foot);
      commit('SET_IS_DONE', data.is_done);
      commit('SET_DONE_YEAR_EST', data.done_year_est);
      commit('SET_COVID_PROTOCOL', data.covid_protocol);
      commit('SET_VIEW_TO_OFFER', data.view_to_offer);
      commit('SET_MIN_RENT_DURATION', data.min_rent_duration);
      commit('SET_BOOKING_FEE_MONTH_AMOUNT', data.booking_fee_month_amount);
      commit('SET_CONDITION_PRIMARY', data.is_condition_primary);

      let selectedTenureType = state.tenureTypeList.find(function(item) {
        return item.id == data.tenure_type_id;
      });
      if (!selectedTenureType) {
        selectedTenureType = null;
      }
      commit('SET_SELECTED_TENURE_TYPE', selectedTenureType);
      if (data.covid_status_id) {
        commit(
          'SET_COVID_STATUS',
          state.covidStatuses.find(status => {
            return status.id === data.covid_status_id;
          }),
        );
      }

      if (data.covid_protocol || data.covid_status_id) {
        commit('SET_USE_PROTOCOL', true);
      }

      // commit('CHANGE_ISNEGOTIABLE',listing.is_negotiable);
      // commit('SHOW_ALLOWED_NEGO',canNego[0]);
      if (data.listing_type === 'R') {
        commit('SET_SELECTED_RENT_TYPE', data.rent_type_id);
        for (let key in data.rent_price) {
          // The key is key
          // The value is obj[key]
          commit('CHANGE_PRICE', {
            rentPeriodId: key,
            price: data.rent_price[key],
          });
          commit('TOGGLE_CHECK_RENT_PERIOD', key);
        }
      } else {
        commit('SET_SELL_PRICE', data.sell_price);
      }
      if (data.discount_price) {
        commit('SET_SHOW_DISCOUNT', true);
        commit('SET_DISCOUNT_PRICE', data.discount_price);
        commit('SET_IS_DISC_PERCENTAGE', false);
      } else if (data.discount_percentage) {
        commit('SET_SHOW_DISCOUNT', true);
        commit('SET_DISCOUNT_PERCENTAGE', data.discount_percentage);
        commit('SET_IS_DISC_PERCENTAGE', true);
      }
      if (data.available_from) {
        commit('SET_AVAILABLE_FROM', parseDateISO(data.available_from));
      }
    } catch (e) {
      throw e;
    }
  },
};
