export default {
  plugins: [
    '@uvue/core/plugins/middlewares',
    // [
    //   '@uvue/core/plugins/middlewares',
    //   // {
    //   //   middlewares: [
    //   //     /**
    //   //      * If user come to this application we check is already logged.
    //   //      * Result will be stored in Vuex to be used anywhere in Vue application.
    //   //      */
    //   //     // async ({ store, $http }) => {
    //   //     //   try {
    //   //     //     const { data } = await $http.get('/api/profile');
    //   //     //     store.commit('global/SET_USER', data);
    //   //     //   } catch (err) {
    //   //     //     // Nothing here...
    //   //     //   }
    //   //     // },
    //   //   ],
    //   // },
    // ],
    '@uvue/core/plugins/asyncData',
    [
      '@uvue/core/plugins/vuex',
      {
        onHttpRequest: true,
        fetch: true,
      },
    ],
    // '@/plugins/middleware',
    '@uvue/core/plugins/errorHandler',
    '@/plugins/errorClear',
    '@/plugins/httpClient',
    '@/plugins/date-fns',
    '@/plugins/initWeb',
    '@/plugins/facebookPixel',
    '@/plugins/ga',
    '@/plugins/navLoader',
    '@/plugins/deviceDetect',
  ],
};
