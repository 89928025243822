export const state = () => ({
  propertyTypes: [],
  mortgageBanks: [],
  listing: null,
  isLoading: true,
  employmentStatuses: [
    {
      id: 1,
      name: 'Employee',
    },
    {
      id: 2,
      name: 'Self Employed',
    },
    {
      id: null,
      name: 'Others (Please Specify)',
    },
  ],
  fullname: null,
  dateOfBirth: null,
  identityNumber: null,
  address: null,
  email: null,
  contactNumber: null,
  employmentStatus: null,
  customEmploymentStatus: null,
  monthlyIncomeValue: null,
  identityImage: null,
  preferredBank: null,
  loanAmount: null,
  tenureInYear: null,
  termsAndConditions: false,
  propertyPrice: null,
  selectedPropertyType: null,
  propertyAddress: null,
  ltv: 0,
});

export const mutations = {
  SET_PROPERTY_TYPES(state, payload) {
    state.propertyTypes = payload;
  },
  SET_MORTGAGE_BANKS(state, payload) {
    state.mortgageBanks = payload;
  },
  SET_LISTING(state, payload) {
    state.listing = payload;
  },
  SET_LOADING_TRUE(state) {
    state.isLoading = true;
  },
  SET_LOADING_FALSE(state) {
    state.isLoading = false;
  },
  SET_LOAN_AMOUNT(state, payload) {
    state.loanAmount = payload;
  },
  SET_TENURE_IN_YEAR(state, payload) {
    state.tenureInYear = payload;
  },
  SET_TERMS_CONDITIONS(state, payload) {
    state.termsAndConditions = payload;
  },
  SET_FULLNAME(state, payload) {
    state.fullname = payload;
  },
  SET_DATE_BIRTH(state, payload) {
    state.dateOfBirth = payload;
  },
  SET_IDENTITY_NUMBER(state, payload) {
    state.identityNumber = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_CONTACT_NUMBER(state, payload) {
    state.contactNumber = payload;
  },
  SET_EMPLOYMENT_STATUS(state, payload) {
    state.employmentStatus = payload;
  },
  SET_CUSTOM_EMPLOYMENT_STATUS(state, payload) {
    state.customEmploymentStatus = payload;
  },
  SET_MONTHLY_INCOME(state, payload) {
    state.monthlyIncomeValue = payload;
  },
  SET_IDENTITY_IMAGE(state, payload) {
    state.identityImage = payload;
  },
  SET_PREFERRED_BANK(state, payload) {
    state.preferredBank = payload;
  },
  SET_PROPERTY_PRICE(state, payload) {
    state.propertyPrice = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
  SET_PROPERTY_ADDRESS(state, payload) {
    state.propertyAddress = payload;
  },
  SET_LTV(state, payload) {
    state.ltv = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.propertyTypes = [];
    state.mortgageBanks = [];
    state.listing = null;
    state.loanAmount = null;
    state.tenureInYear = null;
    state.termsAndConditions = false;
    state.fullname = null;
    state.dateOfBirth = null;
    state.identityNumber = null;
    state.address = null;
    state.email = null;
    state.contactNumber = null;
    state.employmentStatus = null;
    state.customEmploymentStatus = null;
    state.monthlyIncomeValue = null;
    state.identityImage = null;
    state.preferredBank = null;
    state.propertyPrice = null;
    state.selectedPropertyType = null;
    state.propertyAddress = null;
    state.ltv = 0;
  },
};

export const actions = {
  async getFormData({ commit }, listingUuid) {
    try {
      commit('SET_LOADING_TRUE');

      let { data } = await this.$http.get('/api/v2/mortgage/get_form_data', {
        params: {
          listing_uuid: listingUuid,
        },
      });
      commit('SET_PROPERTY_TYPES', data.data.property_types);
      commit('SET_MORTGAGE_BANKS', data.data.mortgage_banks);
      commit('SET_LISTING', data.data.listing);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING_FALSE');
    }
  },
  async submitMortgageRequest({ commit, state }) {
    try {
      commit('SET_LOADING_TRUE');

      let { data } = await this.$http.post('/api/v2/mortgage/submit', {
        listing_uuid: state.listing ? state.listing.uuid : null,
        listing_address: state.listing ? state.listing.address_line_2 : state.propertyAddress,
        sell_price: state.listing ? state.listing.sell_price_raw : state.propertyPrice,
        property_type_id: state.selectedPropertyType ? state.selectedPropertyType.id : null,
        full_name: state.fullname,
        birth_date: state.dateOfBirth,
        contact_number: state.contactNumber,
        email: state.email,
        employment_status: state.employmentStatus.id
          ? state.employmentStatus.name
          : state.customEmploymentStatus,
        home_address: state.address,
        total_monthly_income: state.monthlyIncomeValue,
        // id_number: state.identityNumber,
        // id_number_image_uuid: state.identityImage ? state.identityImage.uuid : null,
        preferred_bank: state.preferredBank,
        loan_amount: state.loanAmount,
        loan_tenure: state.tenureInYear,
      });

      commit('SET_LOADING_FALSE');
      return data;
    } catch (e) {
      console.log('SUBMIT MORTGAGE REQUEST ERROR: ', e);
      throw e;
    } finally {
      commit('SET_LOADING_FALSE');
    }
  },
  getLTV({ commit }, listing) {
    let propertyTypeCategoryId =
      listing && listing.property ? listing.property.property_type_category_id : null;
    let ltv = 0.7;
    if (propertyTypeCategoryId === 2) ltv = 0.75;
    else if (propertyTypeCategoryId === 1) ltv = 0.7;
    commit('SET_LTV', ltv);
    return ltv;
  },
};
