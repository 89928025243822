export const state = () => ({
  bankList: [],
  selectedBank: null,
  accountName: null,
  accountNumber: null,
  email: null,
  password: null,
  loading: true,
  phoneNumber: null,
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PHONE_NUMBER(state, payload) {
    state.phoneNumber = payload;
  },
  SET_BANK_LIST(state, payload) {
    state.bankList = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_PASSWORD(state, payload) {
    state.password = payload;
  },
  SET_SELECTED_BANK(state, payload) {
    state.selectedBank = payload;
  },
  SET_ACCOUNT_NAME(state, payload) {
    state.accountName = payload;
  },
  SET_ACCOUNT_NUMBER(state, payload) {
    state.accountNumber = payload;
  },
};

export const actions = {
  async getFormData({ commit, state }, formData) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/bank_form/get_data', formData);
      commit('SET_BANK_LIST', data.data.banks);
      if (data.data.bank_account) {
        const selectedBank = state.bankList.find(element => {
          return element.id == data.data.bank_account.bank_id;
        });
        commit('SET_SELECTED_BANK', selectedBank);
        commit('SET_ACCOUNT_NAME', data.data.bank_account.account_name);
        commit('SET_ACCOUNT_NUMBER', data.data.bank_account.account_number);
      }
      commit('SET_PHONE_NUMBER', data.data.phone_number);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async postData({ state, commit }) {
    try {
      commit('SET_LOADING', true);

      let formData = {};
      formData.email = state.email;
      formData.password = state.password;
      formData.bank_account_name = state.accountName;
      formData.bank_account_number = state.accountNumber;
      formData.bank_id = state.selectedBank.id;
      formData.phone_number = state.phoneNumber;
      await this.$http.post('/api/v2/bank_form', formData);
      commit('SET_EMAIL', '');
      commit('SET_PASSWORD', '');
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
