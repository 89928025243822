export const state = () => ({
  notificationList: [],
  notificationOverviewList: [],
  unreadCount: 0,
  loadingOverview: false,
  loading: false,
  metaPagination: {},
});

export const mutations = {
  SET_META_PAGINATION(state, meta) {
    state.metaPagination = meta;
  },
  RESTORE_INITIAL_OVERVIEW_STATE(state) {
    state.notificationOverviewList = [];
    state.unreadCount = 0;
  },
  RESTORE_INITIAL_STATE(state) {
    state.metaPagination = {};
    state.notificationList = [];
  },
  READ_NOTIFICATION(state, msgThreadUuid) {
    let clonedNotifList = JSON.parse(JSON.stringify(state.notificationList));
    clonedNotifList.forEach(element => {
      if (element.messageThreadUuid == msgThreadUuid) {
        element.isRead = true;
      }
    });
    state.notificationList = clonedNotifList;
  },
  READ_NOTIFICATION_OVERVIEW(state, msgThreadUuid) {
    let clonedNotifList = JSON.parse(JSON.stringify(state.notificationOverviewList));
    clonedNotifList.forEach(element => {
      if (element.messageThreadUuid == msgThreadUuid) {
        element.isRead = true;
      }
    });
    state.notificationOverviewList = clonedNotifList;
  },
  SET_UNREAD_COUNT(state, payload) {
    state.unreadCount = payload;
  },
  ADD_UNREAD_COUNT(state) {
    state.unreadCount++;
  },
  CUT_UNREAD_COUNT(state) {
    state.unreadCount--;
  },
  SET_NOTIFICATION_LIST(state, payload) {
    state.notificationList = payload;
  },

  SET_NOTIFICATION_OVERVIEW_LIST(state, payload) {
    state.notificationOverviewList = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LOADING_OVERVIEW(state, payload) {
    state.loadingOverview = payload;
  },
  ADD_NOTIFICATION(state, payload) {
    state.notificationList.unshift(payload);
  },
  ADD_NOTIFICATION_OVERVIEW(state, payload) {
    state.notificationOverviewList.unshift(payload);
  },
};

export const actions = {
  async getNotifications({ commit }, page) {
    try {
      commit('RESTORE_INITIAL_STATE');
      commit('SET_LOADING', true);
      if (page === undefined) {
        page = 1;
      }
      const { data } = await this.$http.get('/api/v2/notification/get', {
        params: {
          page: page,
        },
      });
      commit('SET_NOTIFICATION_LIST', data.data);
      commit('SET_META_PAGINATION', data.meta);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getOverview({ commit }) {
    try {
      commit('RESTORE_INITIAL_OVERVIEW_STATE');
      commit('SET_LOADING_OVERVIEW', true);
      const { data } = await this.$http.get('/api/v2/notification/get_overview');
      commit('SET_NOTIFICATION_OVERVIEW_LIST', data.data);
    } finally {
      commit('SET_LOADING_OVERVIEW', false);
    }
  },

  async getUnreadCount({ commit }) {
    commit('RESTORE_INITIAL_STATE');
    const { data } = await this.$http.get('/api/v2/notification/unread_count');
    commit('SET_UNREAD_COUNT', data.data);
  },
};
