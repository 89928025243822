const requireContext = require.context('./sellTransaction', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  ownerUniqueNumber: null,
  sellTransactionUuid: null,
  fkpTemplate: null,
  fkpSignLoading: false,
});

export const mutations = {
  SET_OWNER_UNIQUE_NUMBER(state, payload) {
    state.ownerUniqueNumber = payload;
  },
  SET_FKP_TEMPLATE(state, payload) {
    state.fkpTemplate = payload;
  },
  SET_SELL_TRANSACTION_UUID(state, payload) {
    state.sellTransactionUuid = payload;
  },
  SET_FKP_SIGN_LOADING(state, payload) {
    state.fkpSignLoading = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_OWNER_UNIQUE_NUMBER', null);
  },

  async getFkpTemplate(
    { commit, state },
    {
      ownerName,
      ownerKtp,
      agentName,
      agentCeaNumber,
      lawPracticeName,
      bankName,
      bankAccountNumber,
      lawFirmName,
      solicitorName,
    },
  ) {
    // await this.$http.get('/api/v2/booking/digital-sign-template/' + bookinguuid).then(response => {
    //   commit('set_digital_sign_template', response.data.data.template);
    // });
    try {
      commit('SET_FKP_SIGN_LOADING', true);
      let { data } = await this.$http.get(
        `/api/v2/sell_transaction/get_otp_form_template/${state.sellTransactionUuid}`,
        {
          params: {
            owner_name: ownerName,
            owner_nric: ownerKtp,
            agent_name: agentName,
            agent_cea_number: agentCeaNumber,
            law_practice_name: lawPracticeName,
            bank_name: bankName,
            bank_account_number: bankAccountNumber,
            law_firm_name: lawFirmName,
            solicitor_name: solicitorName,
          },
        },
      );
      commit('SET_FKP_TEMPLATE', data.data.template);
      // commit('SET_BOOKING_INFO', data.data.booking_info);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_FKP_SIGN_LOADING', false);
    }
  },

  async signFkp({ commit, state }) {
    try {
      commit('SET_FKP_SIGN_LOADING', true);
      let { data } = await this.$http.post('/api/v2/sell_transaction/applicant_sign', {
        uuid: state.sellTransactionUuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_FKP_SIGN_LOADING', false);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadFkp({}, sellTransactionUuid) {
    await this.$http
      .get('/api/v2/sell_transaction/get_agreement', {
        params: {
          uuid: sellTransactionUuid,
        },
      })
      .then(result => {
        let filePdf = result.data.data;
        window.open(filePdf, '_blank');
        // let blob = new Blob(filePdf,{type:'application/pdf'})
        // url = window.URL.createObjectURL(blob)
        // window.open(url);
      });
  },

  // eslint-disable-next-line no-empty-pattern
  async ownerAccept({}, { uuid, finalPrice }) {
    try {
      let { data } = await this.$http.post('/api/v2/sell_transaction/owner_accept', {
        uuid,
        final_price: finalPrice,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },

  async checkSignFkp({ commit }, uuid) {
    try {
      commit('SET_FKP_SIGN_LOADING', true);
      let response = await this.$http.post(`/api/v2/sell_transaction/sign_check?uuid=${uuid}`);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_FKP_SIGN_LOADING', false);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async cancelBeforePayment({}, uuid) {
    try {
      let { data } = await this.$http.post('/api/v2/sell_transaction/cancel_before_payment', {
        uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async cancelApplicantSign({}, uuid) {
    try {
      let { data } = await this.$http.post('/api/v2/sell_transaction/cancel_applicant_sign', {
        uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async cancelOwnerConfirmBooking({}, uuid) {
    try {
      let { data } = await this.$http.post(
        '/api/v2/sell_transaction/cancel_owner_confirm_booking',
        {
          uuid,
        },
      );
      return data.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async confirmBookingFee({}, uuid) {
    try {
      let { data } = await this.$http.post('/api/v2/sell_transaction/confirm_booking_fee', {
        uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },
};
