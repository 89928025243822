import Vue from 'vue';
import VueRouter from 'vue-router';
import MAuth from '@/middleware/auth';
import MPasswordCreated from '@/middleware/passwordCreated';
import MCreatePassword from '@/middleware/createPassword';
import MGuest from '@/middleware/guest';
import MNonAdminListingPartner from '@/middleware/nonAdminListingPartner';

Vue.use(VueRouter);
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/rent',
    name: 'home-rent',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/buy',
    name: 'home-buy',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('login'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: loadView('register'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/forget-pass',
    name: 'forget-pass',
    component: loadView('forget-pass'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/user/activation/:token',
    name: 'user-activation',
    component: loadView('user/activation/_token'),
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    component: loadView('password/reset/_token'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/detail/:hashId/:title',
    name: 'listing-detail',
    component: loadView('detail/_hashId/_title/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  // {
  //   path: '/request-to-book',
  //   name: 'request-to-book',
  //   component: loadView('request-to-book'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  {
    path: '/user/create-password',
    name: 'create-password',
    component: loadView('user/create-password'),
    meta: {
      middlewares: [MAuth, MPasswordCreated],
    },
  },
  // // {
  // //   path: '/dashboard/notifications',
  // //   name: 'notifications',
  // //   component: loadView('dashboard/notifications/index'),
  // //   meta: {
  // //     middlewares: [MAuth, MCreatePassword],
  // //   },
  // // },
  // {
  //   path: '/password/reset/:token',
  //   name: 'reset-password',
  //   component: loadView('password/reset/_token'),
  //   meta: {
  //     middlewares: [MGuest],
  //   },
  // },
  // {
  //   path: '/booking',
  //   name: 'booking-info-review',
  //   component: loadView('booking/index'),
  //   meta: {
  //     middlewares: [MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/ajukan-cicilan',
  //   name: 'ajukan-cicilan',
  //   component: loadView('ajukan-cicilan/index'),
  //   meta: {
  //     middlewares: [MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/booking/how-to-pay/:uuid',
  //   name: 'how-to-pay',
  //   component: loadView('booking/how-to-pay/_uuid/index'),
  //   meta: {
  //     middlewares: [MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/booking/edit/:orderNumber',
  //   name: 'edit-booking-payment',
  //   component: loadView('booking/edit/_orderNumber/index'),
  //   meta: {
  //     middlewares: [MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/booking/detail',
  //   name: 'booking-detail',
  //   component: loadView('booking/detail'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  {
    path: '/dashboard/mylisting',
    name: 'my-listing',
    component: loadView('dashboard/mylisting/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },

  {
    path: '/mybooking/detail',
    name: 'mybooking-detail',
    component: loadView('mybooking/detail/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  // {
  //   path: '/dashboard/mybooking',
  //   name: 'my-booking',
  //   component: loadView('dashboard/mybooking/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/dashboard/myinbox',
  //   name: 'my-inbox',
  //   component: loadView('dashboard/myinbox/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  //
  // {
  //   path: '/dashboard/myinbox/message/:threadUuid',
  //   name: 'my-inbox-detail',
  //   component: loadView('dashboard/myinbox/message/_threadUuid/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  {
    path: '/profile',
    name: 'profile',
    component: loadView('profile/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/dashboard/favorites',
    name: 'favorites',
    component: loadView('dashboard/favorites/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },

  {
    path: '/transaction/checkout',
    name: 'transaction-checkout',
    component: loadView('transaction/checkout/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },

  {
    path: '/transaction/pay/:orderNumber',
    name: 'transaction-pay',
    component: loadView('transaction/pay/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing/add',
    name: 'add-listing',
    component: loadView('listing/add/_listingType/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing/add/:listingType',
    name: 'add-listing-params',
    component: loadView('listing/add/_listingType/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing/edit/:uuid',
    name: 'edit-listing',
    component: loadView('listing/edit/_uuid/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  // {
  //   path: '/promotions',
  //   name: 'promotions',
  //   component: loadView('promotions/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/promo/:uuid',
  //   name: 'promo',
  //   component: loadView('promo/_uuid/index'),
  //   meta: {
  //     middlewares: [MCreatePassword],
  //   },
  // },
  // {
  //   path: '/good-deals/:uuid',
  //   name: 'good-deals-detail',
  //   component: loadView('good-deals/_uuid/index'),
  //   meta: {
  //     middlewares: [MCreatePassword],
  //   },
  // },
  // {
  //   path: '/listing-deals',
  //   name: 'listing-deals',
  //   component: loadView('listing-deals'),
  //   meta: {
  //     middlewares: [MCreatePassword],
  //   },
  // },
  {
    path: '/search-agent',
    name: 'search-agent',
    component: loadView('search-agent'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/:listing_type/search',
    name: 'search',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/:listing_type/search/:propertyType',
    name: 'search',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/:listing_type/search/:propertyType/:query',
    name: 'search',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/user/:userName/:userHashId',
    name: 'search-user-listing',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MNonAdminListingPartner],
    },
  },
  // {
  //   path: '/corporate-client',
  //   name: 'corporate-client',
  //   component: loadView('corporate-client'),
  //   meta: {
  //     middlewares: [MCreatePassword, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/contact-us',
  //   name: 'contact-us',
  //   component: loadView('contact-us'),
  //   meta: {
  //     middlewares: [MCreatePassword, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/about-us',
  //   name: 'about-us',
  //   component: loadView('about-us'),
  //   meta: {
  //     middlewares: [MCreatePassword, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/career',
  //   name: 'career',
  //   component: loadView('career'),
  //   meta: {
  //     middlewares: [MCreatePassword, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/grid',
  //   name: 'grid',
  //   component: loadView('grid'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/mobile-apps',
  //   name: 'mobile-apps',
  //   component: loadView('mobile-apps'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  {
    path: '/change-password',
    name: 'change-password',
    component: loadView('change-password/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: loadView('privacy-policy'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/term-of-service',
    name: 'term-of-service',
    component: loadView('term-of-service'),
    meta: {
      middlewares: [],
    },
  },
  // {
  //   path: '/property-partner/add',
  //   name: 'property-partner-add',
  //   component: loadView('property-partner/add'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/property-partner/:property',
  //   name: 'property-partner',
  //   component: loadView('property-partner/_property/index'),
  //   meta: {
  //     middlewares: [MCreatePassword],
  //   },
  // },
  // {
  //   path: '/project-partner/:hashId/:title',
  //   name: 'project-partner',
  //   component: loadView('project-partner/_hashId/_title/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MNonAdminListingPartner],
  //   },
  // },
  {
    path: '/help',
    name: 'help-index',
    component: loadView('help/_type/index'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/help/:type',
    name: 'help',
    component: loadView('help/_type/index'),
    meta: {
      middlewares: [],
    },
  },
  // {
  //   path: '/invoice',
  //   name: 'invoice',
  //   component: loadView('invoice'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/booking/sign/:uuid',
  //   name: 'sign',
  //   component: loadView('booking/sign/_uuid/index'),
  //   meta: {
  //     middlewares: [MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/booking/sign-otp/:uuid',
  //   name: 'sign',
  //   component: loadView('booking/sign-otp/_uuid/index'),
  //   meta: {
  //     middlewares: [MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/receipt',
  //   name: 'receipt',
  //   component: loadView('transaction-document'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  {
    path: '/directory/:propertyType',
    name: 'directory',
    component: loadView('directory/index'),
    meta: {
      middlewares: [],
    },
  },
  // {
  //   path: '/notifications',
  //   name: 'notifications',
  //   component: loadView('notifications/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/inbox',
  //   name: 'inbox',
  //   component: loadView('inbox/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/inbox/:uuid',
  //   name: 'inbox-detail',
  //   component: loadView('inbox/_uuid/index'),
  //   meta: {
  //     middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
  //   },
  // },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: loadView('news/index'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/news/category/:categoryIdName',
  //   name: 'newsByCategory',
  //   component: loadView('news/index'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/news/:newsTitle/:hashId',
  //   name: 'newsDetail',
  //   component: loadView('news/detail'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  {
    path: '/mortgage',
    name: 'mortgage',
    component: loadView('mortgage/index'),
    meta: {
      middlewares: [],
    },
  },

  // // Listing Partner URL harus ditaruh paling bawah, kalau tidak nanti override URL page lain.
  // {
  //   path: '/:listingPartnerUrl',
  //   name: 'listing-partner',
  //   component: loadView('_listingPartnerUrl/index'),
  //   meta: {
  //     middlewares: [MCreatePassword],
  //   },
  // },
  // {
  //   path: '/:listingPartnerUrl/dashboard',
  //   name: 'listing-partner-dashboard',
  //   component: loadView('_listingPartnerUrl/dashboard'),
  //   meta: {
  //     middlewares: [MAdminListingPartner],
  //   },
  // },
  { path: '*', component: loadView('404') },
];

export default () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          selector: to.hash,
        };
      } else {
        return { x: 0, y: 0 };
      }
    },
  });
  // ############### Gtag Manager Installation ############
  const VueGtag = require('vue-gtag');
  Vue.use(
    VueGtag,
    {
      config: {
        id: 'G-0ZY63R17WX',
      },
      // router,
    },
    router,
  );
  // #################################################
  return router;
};
