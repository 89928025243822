export const state = () => ({
  isLoading: false,
  banners: [],
});

export const getters = {};

export const mutations = {
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_BANNERS(state, payload) {
    state.banners = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async getBanners({ commit }) {
    let response;
    try {
      commit('SET_IS_LOADING', true);
      response = await this.$http.get('/api/v2/search', {
        params: {
          paginate: 30,
          listing_highlight: true,
        },
      });
      response = response.data.data.data;
      commit('SET_BANNERS', response);
    } catch (e) {
      console.log('ERROR GET BANNERS: ', e);
    } finally {
      commit('SET_IS_LOADING', false);
    }

    return response;
  },
};
