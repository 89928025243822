import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const numberFormats = {
  'en-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
      currencyDisplay: 'symbol',
    },
  },
};

function loadLocaleMessages() {
  // const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  // const messages = {};
  // locales.keys().forEach(key => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1];
  //     messages[locale] = locales(key);
  //   }
  // });
  // return messages;
  return {
    en: Object.assign(require('@/locales/en.json'), {
      fields: {
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm Password',
        fullname: 'Full Name',
        old_pass: 'Old Password',
        name: 'Name',
        message: 'Message',
        province: 'Province',
        city: 'City',
        district: 'District',
        village: 'Village',
        agreement: 'Agreement',
        identity_card_number: 'Identity Number (ID CARD/PASSPORT)',
        phone: 'Phone Number',
        address_line_2: 'Address Line 2',
        duration: 'Duration',
        newMessage: 'New Message',
        nego_value: 'Negotiation Value',
        profile: 'Profile',
        address: 'Address',
        industry: 'Industry of the Company',
        website: 'Website',
        size: 'Estimate Size',
        contact: 'Contact',
        type: 'Type of Company',
        estimate_pax: 'Estimate Pax',
        monthly_budget: 'Monthly Budget',
        yearly_budget: 'Yearly Budget',
        property_type: 'Property Type',
        purchasePrice: 'Purchase Price',
        loanAmount: 'Mortgage Amount',
        interestRate: 'Interest Rate',
        tenureInYear: 'Loan Tenure',
      },
    }),
  };
}

let i18n = new VueI18n({
  numberFormats,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

i18n.n150 = i18n.n;

i18n.n = function(nValue, ...args) {
  // let numberFormatter = this.getNumberFormat(this.locale) // get NumberFormat Based on locale
  // if (numberFormatter[nStyle].abbreviate) { // if custom property is true
  //   let newValue = Math.round(nValue/1000000) // divide 10^6 for millions, divide 10^3 for K .etc
  //   return this.n1(newValue, nStyle).replace(/(\d[\d|,]*)(\.\d+)?/, '$1 $2M')
  // }
  // return this.n150(nValue, nStyle); // for others , use default i18n.n to process.
  function includes(arr, item) {
    return !!~arr.indexOf(item);
  }
  function isString(val) {
    return typeof val === 'string';
  }
  function isObject(obj) {
    return obj !== null && typeof obj === 'object';
  }

  const numberFormatKeys = [
    'style',
    'currency',
    'currencyDisplay',
    'useGrouping',
    'minimumIntegerDigits',
    'minimumFractionDigits',
    'maximumFractionDigits',
    'minimumSignificantDigits',
    'maximumSignificantDigits',
    'localeMatcher',
    'formatMatcher',
    'unit',
  ];
  let locale = this.locale;
  let key = null;
  let options = null;

  if (args.length === 1) {
    if (isString(args[0])) {
      key = args[0];
    } else if (isObject(args[0])) {
      if (args[0].locale) {
        locale = args[0].locale;
      }
      if (args[0].key) {
        key = args[0].key;
      }

      // Filter out number format options only
      options = Object.keys(args[0]).reduce((acc, key) => {
        if (includes(numberFormatKeys, key)) {
          return Object.assign({}, acc, { [key]: args[0][key] });
        }
        return acc;
      }, null);
    }
  } else if (args.length === 2) {
    if (isString(args[0])) {
      key = args[0];
    }
    if (isString(args[1])) {
      locale = args[1];
    }
  }

  return `S${this._n(nValue, locale, key, options)}`;
};

export default i18n;
