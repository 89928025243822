export const state = () => ({
  agreementType: 'none',
  isLoading: false,
  uuid: null,
  customAgreement: null,
  ownerName: null,
  ownerKtp: null,
  ownerEmail: null,
  selectedBank: null,
  bankName: null,
  bankAccountNumber: null,
  bankAccountName: null,
  agentName: null,
  agentCeaNumber: null,
});

export const getters = {
  formData(state) {
    let formData = {
      uuid: state.uuid,
      owner_name: state.ownerName,
      owner_ktp: state.ownerKtp,
      owner_email: state.ownerEmail,
      // bank_name: state.selectedBank.name,
      bank_name: state.bankName,
      bank_account_number: state.bankAccountNumber,
      bank_account_name: state.bankAccountName,
      agent_name: state.agentName,
      agent_cea_number: state.agentCeaNumber,
    };
    if (state.agreementType === 'default') {
      formData.use_default_agreement = true;
    } else if (
      state.agreementType === 'custom' &&
      state.customAgreement &&
      state.customAgreement.uuid
    ) {
      formData.custom_agreement_uuid = state.customAgreement.uuid;
    }
    return formData;
  },
};

export const mutations = {
  SET_AGREEMENT_TYPE(state, payload) {
    state.agreementType = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_CUSTOM_AGREEMENT(state, payload) {
    state.customAgreement = payload;
  },
  SET_OWNER_NAME(state, payload) {
    state.ownerName = payload;
  },
  SET_OWNER_KTP(state, payload) {
    state.ownerKtp = payload;
  },
  SET_OWNER_EMAIL(state, payload) {
    state.ownerEmail = payload;
  },
  SET_SELECTED_BANK(state, payload) {
    state.selectedBank = payload;
  },
  SET_BANK_NAME(state, payload) {
    state.bankName = payload;
  },
  SET_BANK_ACCOUNT_NUMBER(state, payload) {
    state.bankAccountNumber = payload;
  },
  SET_BANK_ACCOUNT_NAME(state, payload) {
    state.bankAccountName = payload;
  },
  SET_AGENT_NAME(state, payload) {
    state.agentName = payload;
  },
  SET_AGENT_CEA_NUMBER(state, payload) {
    state.agentCeaNumber = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.agreementType = 'none';
    state.isLoading = false;
    state.uuid = null;
    state.customAgreement = null;
    state.ownerName = null;
    state.ownerKtp = null;
    state.ownerEmail = null;
    state.selectedBank = null;
    state.bankName = null;
    state.bankAccountNumber = null;
    state.bankAccountName = null;
    state.agentName = null;
    state.agentCeaNumber = null;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async postAcceptBooking({ commit, getters }) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$http.post('api/v2/rent_transaction/accept', getters.formData);
      console.log('RESPONSE booking req: ', data.data);

      return data.data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
