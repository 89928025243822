export const state = () => ({
  selectedHelp: null,
  helpList: [
    {
      url: 'what-is-propfix',
      title: 'FAQ.rentfixgeneral',
    },
    {
      url: 'about-listing',
      title: 'FAQ.aboutListingHeader',
    },
    {
      url: 'account-deletion',
      title: 'FAQ.deleteAccount.header',
    },
  ],
});

export const getters = {};

export const mutations = {
  SET_SELECTED_HELP(state, payload) {
    state.selectedHelp = payload;
  },
};

export const actions = {};
