export const state = () => ({
  photoList: [],
  youtubeUrl: '',
  panoEmbedUrl: '',
  floorPlanList: [],
});

export const getters = {
  formData(state) {
    const photos = JSON.parse(JSON.stringify(state.photoList));
    let data;
    if (Object.keys(photos).length > 0) {
      const uuids = photos.map(function(photo) {
        return photo.uuid;
      });

      const primaryPhoto = photos.find(function(photo) {
        return photo.isPrimary;
      });
      data = {
        primaryPhoto: primaryPhoto ? primaryPhoto.uuid : null,
        photoUuid: uuids,
        youtubeUrl: state.youtubeUrl,
        pano_embed_url: state.panoEmbedUrl,
      };
    } else {
      data = {
        primaryPhoto: null,
        pano_embed_url: state.panoEmbedUrl,
      };
    }
    const floorPlans = JSON.parse(JSON.stringify(state.floorPlanList));

    if (Object.keys(floorPlans).length > 0) {
      data.floor_plan_uuids = floorPlans.map(function(photo) {
        return photo.uuid;
      });
    }
    return data;
  },
};

export const mutations = {
  UPDATE_YOUTUBE_URL(state, url) {
    state.youtubeUrl = url;
  },
  UPDATE_PANO_EMBED_URL(state, url) {
    state.panoEmbedUrl = url;
  },
  RESTORE_INITIAL_STATE(state) {
    state.photoList = [];
    state.floorPlanList = [];
    state.panoEmbedUrl = [];
    state.youtubeUrl = [];
  },

  ADD_PHOTO(state, payload) {
    state.photoList.push(payload);
  },
  ADD_FLOOR_PLAN(state, payload) {
    state.floorPlanList.push(payload);
  },

  REMOVE_PHOTO(state, arrIndex) {
    state.photoList.splice(arrIndex, 1);
  },
  REMOVE_FLOOR_PLAN(state, arrIndex) {
    state.floorPlanList.splice(arrIndex, 1);
  },
  TOGGLE_PRIMARY_PHOTO(state, arrIndex) {
    let photo = state.photoList[arrIndex];
    if (photo.isPrimary) {
      photo.isPrimary = false;
      state.photoList[arrIndex] = photo;
    } else {
      state.photoList = state.photoList.map(function(element, index) {
        element.isPrimary = index == arrIndex;
        return element;
      });
    }
  },
  ADD_PHOTOS(state, photos) {
    photos.forEach(function(photo) {
      if (photo.photo) {
        state.photoList.push({
          fileName: photo.file_name,
          isPrimary: photo.is_primary_photo == 1,
          src: photo.photo_image,
          uuid: photo.photo.uuid,
        });
      }
    });
  },
  ADD_FLOOR_PLANS(state, photos) {
    photos.forEach(function(photo) {
      state.floorPlanList.push({
        fileName: photo.file_name,
        src: photo.photo_image,
        uuid: photo.photo.uuid,
      });
    });
  },
};
export const actions = {
  applyEditData({ commit }, { data }) {
    commit('UPDATE_YOUTUBE_URL', data.youtube_url);
    if (data.photo_data.listing_photos) {
      if (data.photo_data.listing_photos.length > 0) {
        commit('ADD_PHOTOS', data.photo_data.listing_photos);
      }
    }
    if (data.floor_plans) {
      if (data.floor_plans.length > 0) {
        commit('ADD_FLOOR_PLANS', data.floor_plans);
      }
    }
    commit('UPDATE_PANO_EMBED_URL', data.pano_embed_url);
  },
};
