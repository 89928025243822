const requireContext = require.context('./myinbox', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  messageThreadList: [],
  messageThreadOverview: [],
  metaPagination: {},
  openDetail: false,
  loading: false,
  unreadCount: 0,
});

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.messageThreadList = [];
    state.messageThreadOverview = [];
    state.metaPagination = {};
    state.openDetail = false;
    state.unreadCount = 0;
  },
  SET_UNREAD_COUNT(state, payload) {
    state.unreadCount = payload;
  },
  SET_MESSAGE_THREAD_LIST(state, payload) {
    state.messageThreadList = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_MESSAGE_THREAD_OVERVIEW(state, payload) {
    state.messageThreadOverview = payload;
  },
  SET_META_PAGINATION(state, payload) {
    state.meta = payload;
  },
  SET_OPEN_DETAIL(state, payload) {
    state.openDetail = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async contactHost({}, requestData) {
    const result = await this.$http.post('/api/v1/' + requestData.uuid + '/contact_host', {
      message: requestData.message,
      applicant_id: requestData.applicantId,
    });
    return result.data;
  },
  async getAllThreads({ commit }, page) {
    try {
      commit('SET_LOADING', true);
      commit('SET_MESSAGE_THREAD_LIST', []);
      commit('SET_META_PAGINATION', {});
      if (page === undefined) {
        page = 1;
      }
      const { data } = await this.$http.get('/api/v2/myinbox/get_all_threads', {
        params: {
          page: page,
          paginate: 10,
        },
      });
      commit('SET_MESSAGE_THREAD_LIST', data.data);
      commit('SET_META_PAGINATION', data.meta);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getOverview({ commit }) {
    commit('SET_MESSAGE_THREAD_OVERVIEW', []);
    const { data } = await this.$http.get('/api/v2/myinbox/get_overview');
    commit('SET_MESSAGE_THREAD_OVERVIEW', data.data);
  },
  async getUnreadCount({ commit }) {
    commit('RESTORE_INITIAL_STATE');
    const { data } = await this.$http.get('/api/v2/myinbox/unread_count');
    commit('SET_UNREAD_COUNT', data.data);
  },
};
