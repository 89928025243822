/* eslint-disable no-unused-vars,no-empty-pattern */
import CookieJs from 'js-cookie';

export const state = () => ({
  defaultLang: 'en',
  defaultUrl: 'https://propfix.sg',
  defaultType: 'website',
  defaultTitle:
    'Propfix - Buy, Rent and Sell Singapore Commercial, Industrial & Residential Properties',
  defaultDescription:
    'Find your commercial, industrial and residential spaces here on Propfix. We make full occupancy happen. Propfix will help lease and sell all your properties ranging from house, apartment, shophouse, office, warehouse and factory from across Singapore.',
  defaultOgImage:
    'https://rentfixs3-sg.s3-ap-southeast-1.amazonaws.com/public/static/img/static/og-image.png',
  content: {
    title: state.defaultTitle,
    htmlAttrs: {
      lang: CookieJs.get('locale') ? CookieJs.get('locale') : 'en',
    },
    meta: [
      { hid: 'og-url', property: 'og:url', content: state.defaultUrl },
      { hid: 'og-type', property: 'og:type', content: state.defaultType },
      {
        hid: 'og-description',
        property: 'og:description',
        content: state.defaultDescription,
      },
      {
        hid: 'description',
        name: 'description',
        content: state.defaultDescription,
      },
      {
        hid: 'og-image',
        property: 'og:image',
        content: state.defaultOgImage,
      },
      {
        hid: 'og-image-alt',
        property: 'og:image:alt',
        content: 'Propfix.sg',
      },
    ],
    // script: [
    //   {
    //     src:
    //       'https://maps.googleapis.com/maps/api/js?key=' +
    //       'AIzaSyCrYqjA_5Jnbm_qygdHMY7vWgCvcMsoOI0' +
    //       '&libraries=places',
    //     defer: true,
    //   },
    // ],
  },
});
export const mutations = {
  // TODO Function ini harus dipindah jadi action, karena CookieJs return undefined saat dipanggil di fetch pertama kali, lebih baik ambil locale dari rootState global.js
  SET_CONTENT(state, payload) {
    state.content = payload;

    if (!state.content.htmlAttr) {
      state.content.htmlAttrs = {};
    }

    if (!state.content.htmlAttrs.lang) {
      state.content.htmlAttrs.lang = CookieJs.get('locale') ? CookieJs.get('locale') : 'en';
    }

    if (state.content.meta) {
      if (!state.content.meta.some(el => el.hid === 'og-url')) {
        state.content.meta.push({
          hid: 'og-url',
          property: 'og:url',
          content: state.defaultUrl,
        });
      }
      if (!state.content.meta.some(el => el.hid === 'og-type')) {
        state.content.meta.push({
          hid: 'og-type',
          property: 'og:type',
          content: state.defaultType,
        });
      }
      if (!state.content.meta.some(el => el.hid === 'og-image')) {
        state.content.meta.push({
          hid: 'og-image',
          property: 'og:image',
          content: state.defaultOgImage,
        });
      }
      if (!state.content.meta.some(el => el.hid === 'og-image-alt')) {
        state.content.meta.push({
          hid: 'og-image-alt',
          property: 'og:image:alt',
          content: 'Propfix.sg',
        });
      }
    }
  },
  // TODO Function ini harus dipindah jadi action, karena CookieJs return undefined saat dipanggil di fetch pertama kali, lebih baik ambil locale dari rootState global.js
  RESTORE_DEFAULT_VALUE(state, route) {
    const title =
      'Propfix - Buy, Rent and Sell Singapore Commercial, Industrial & Residential Properties';
    let addOn = '';
    if (route.name) {
      let words = route.name.split('-');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      addOn += ` | ${words.join(' ')}`;
    }
    state.content = {
      title: title + addOn,
      htmlAttrs: {
        lang: CookieJs.get('locale') ? CookieJs.get('locale') : 'en',
      },
      meta: [
        { hid: 'og-url', property: 'og:url', content: state.defaultUrl + route.fullPath },
        { hid: 'og-type', property: 'og:type', content: state.defaultType },
        {
          hid: 'og-description',
          property: 'og:description',
          content: state.defaultDescription + addOn,
        },
        {
          hid: 'description',
          name: 'description',
          content: state.defaultDescription + addOn,
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: state.defaultOgImage,
        },
        {
          hid: 'og-image-alt',
          property: 'og:image:alt',
          content: 'Propfix.sg',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: state.defaultUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
  },
};

export const actions = {
  // eslint-disable-next-line
  async contactEnquiry({commit}, requestData) {
    return await this.$http.post('/api/v2/contact/enquiry', requestData).then(result => {
      return result.data;
    });
  },
};
