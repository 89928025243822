/* eslint-disable */
export default {
  beforeCreate({ app, router }) {
    if (process.client) {
      /*
      ** Only run on client-side and only in production mode
      */
      if (process.env.NODE_ENV !== 'production') return;
      /*
      ** Include Google Analytics Script
      */
      (function(i,s,o,g,r,a,m){
        i.GoogleAnalyticsObject=r;
        i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments);
        };
        i[r].l=1*new Date();
        a=s.createElement(o);
        m=s.getElementsByTagName(o)[0];
        a.async=1;
        a.src=g;
        m.parentNode.insertBefore(a,m);
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      /*
      ** Set the current page
      */
      ga('create', 'G-0ZY63R17WX', 'auto');

      router.afterEach((to, from) => {
        /*
        ** We tell Google Analytics to add a `pageview`
        */
        ga('set', 'page', to.fullPath);
        ga('send', 'pageview');
      });
    }
  },
};