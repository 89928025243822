import Vue from 'vue';
import {
  BadgePlugin,
  CardPlugin,
  AlertPlugin,
  ProgressPlugin,
  DropdownPlugin,
  ButtonPlugin,
  FormCheckboxPlugin,
  FormTextareaPlugin,
  TabsPlugin,
  NavbarPlugin,
  NavPlugin,
  CollapsePlugin,
  PopoverPlugin,
  ToastPlugin,
} from 'bootstrap-vue';
// import { BootstrapVue } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BadgePlugin);
Vue.use(CardPlugin);
Vue.use(AlertPlugin);
Vue.use(ProgressPlugin);
Vue.use(DropdownPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(TabsPlugin);
Vue.use(NavbarPlugin);
Vue.use(NavPlugin);
Vue.use(CollapsePlugin);
Vue.use(PopoverPlugin);
Vue.use(ToastPlugin);
