export const state = () => ({
  loading: false,
  uuid: null,
  newPrice: 0,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_NEW_PRICE(state, payload) {
    state.newPrice = payload;
  },
  RESTORE_INITIAL_STATE() {
    state.newPrice = 0;
  },
};

export const actions = {
  async accept({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.post('/api/v2/negotiation/accept', { uuid: state.uuid });
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async cancel({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.post('/api/v2/negotiation/cancel', { uuid: state.uuid });
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async renegotiate({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.post('/api/v2/negotiation/renegotiate', {
        uuid: state.uuid,
        new_price: state.newPrice,
      });
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
