export const state = () => ({
  orderNumber: null,
  bookingInfo: null,
  paymentInfo: null,
  loading: true,
  paymentMethod: 'credit_card',
});

export const mutations = {
  SET_ORDER_NUMBER(state, payload) {
    state.orderNumber = payload;
  },
  SET_BOOKING_INFO(state, payload) {
    state.bookingInfo = payload;
  },
  SET_PAYMENT_INFO(state, payload) {
    state.paymentInfo = payload;
  },
  SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_PAYMENT_METHOD', 'credit_card');
    commit('SET_ORDER_NUMBER', null);
    commit('SET_BOOKING_INFO', null);
    commit('SET_PAYMENT_INFO', null);
  },

  async checkAccessAllowed({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/v2/checkout/pay/access_detail_allowed', {
        params: {
          order_number: state.orderNumber,
        },
      });
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getData({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/v2/checkout/pay/get_data', {
        params: {
          order_number: state.orderNumber,
        },
      });
      console.log('GET DATA', data.data);
      commit('SET_BOOKING_INFO', data.data.booking_info);
      commit('SET_PAYMENT_INFO', data.data.payment_info);
      if (data.data.billing_info) {
        dispatch(
          'checkout/billingInfo/applyGetData',
          Object.assign(
            {
              new_price: !data.data.booking_info.listing.view_to_offer
                ? data.data.booking_info.listing.sell_price_raw
                : null,
            },
            data.data.billing_info,
          ),
          { root: true },
        );
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async checkPaymentAllowed({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/v2/checkout/pay/is_allowed', {
        params: {
          order_number: state.orderNumber,
        },
      });
      return data.data;
      // console.log('GET DATA', data.data);
      // commit('SET_BOOKING_INFO', data.data.booking_info);
      // commit('SET_PAYMENT_INFO', data.data.payment_info);
      // if(data.data.billing_info){
      //   dispatch('checkout/billingInfo/applyGetData', data.data.billing_info, { root: true });
      // }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async submit({ commit, state }, billingInfo) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/v2/checkout/pay/submit', {
        // let { data } = await this.$http.post('/api/v2/sell_transaction/pay', {
        order_number: state.orderNumber,
        payment_method: state.paymentMethod,
        billing_info: billingInfo,
      });
      if (data.data) {
        return data.data;
      }
      return data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
