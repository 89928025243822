export const state = () => ({
  landCertificateList: [],
  certificateNumber: '',
  totalNumberOfFloor: '',
  floor: '',
  unit: '',
  unitName: '',
  otherFacilities: [''],
  sharedFacilities: [''],
  facilityList: [],
  facilityRules: null,
  furnishingConditionList: [],
  selectedFurnishCond: null,
  defaultAddState: {},
});

export const getters = {
  facilitiesWithUnit(state) {
    return state.facilityList.filter(function(facility) {
      return facility.unitName !== null && facility.allowed;
    });
  },
  facilitiesWithoutUnit(state) {
    const excludedId = [26, 27, 28];
    return state.facilityList.filter(function(facility) {
      return (
        facility.unitName === null &&
        !excludedId.includes(parseInt(facility.id)) &&
        facility.allowed
      );
    });
  },
  facilityRadio(state) {
    const includedId = [26, 27, 28];
    return state.facilityList.filter(function(facility) {
      return includedId.includes(parseInt(facility.id));
    });
  },
  selectedFacilityRadio(state, getters) {
    const selected = getters.facilityRadio.find(element => {
      return element.checked;
    });
    if (selected) {
      return parseInt(selected.id);
    }
    return null;
  },
  selectedLandCertificateId(state) {
    let selectedLandCertificate = state.landCertificateList.find(function(landCertificate) {
      return landCertificate.selected;
    });
    if (selectedLandCertificate) {
      return selectedLandCertificate.id;
    }
    return null;
  },
  selectedLandCertificateText(state) {
    let selectedLandCertificate = state.landCertificateList.find(function(landCertificate) {
      return landCertificate.selected;
    });
    if (selectedLandCertificate) {
      return selectedLandCertificate.text;
    }
    return null;
  },

  landCertificateOptionsId(state) {
    return state.landCertificateList.map(function(item) {
      return item.id;
    });
  },
  landCertificateOptionLabel: state => landCertificateId => {
    const landCertificate = state.landCertificateList.find(function(landCertificate) {
      return landCertificate.id == landCertificateId;
    });
    return landCertificate.name;
  },

  facilityAmountFormData(state) {
    let facilities = state.facilityList;
    return facilities.map(function(facility) {
      return facility.amount;
    });
  },
  selectedFacilityIds(state) {
    let selectedFacilities = state.facilityList.filter(function(facility) {
      return facility.checked;
    });
    return selectedFacilities.map(function(facility) {
      return facility.id;
    });
  },
  formData(state, getters) {
    let otherFacilities = state.otherFacilities;
    let filteredOtherFacilities = otherFacilities.filter(function(otherFacility) {
      return otherFacility !== '' || otherFacility !== null;
    });
    let sharedFacilities = state.sharedFacilities;
    let filteredSharedFacilities = sharedFacilities.filter(function(sharedFacility) {
      return sharedFacility !== '' || sharedFacility !== null;
    });
    const selectedFacilityIds = getters.selectedFacilityIds;
    let tempFacility = {};
    if (state.facilityList.length !== 0) {
      selectedFacilityIds.forEach(id => {
        const selectedFacility = state.facilityList.find(data => {
          return data.id == id;
        });

        if (selectedFacility.amount === undefined) {
          tempFacility[selectedFacility.id] = null;
        } else {
          if (!Number.isNaN(parseInt(selectedFacility.amount))) {
            tempFacility[selectedFacility.id] = parseInt(selectedFacility.amount);
          } else {
            tempFacility[selectedFacility.id] = null;
          }
        }
      });
    }
    if (Object.keys(tempFacility).length === 0) {
      tempFacility = null;
    }
    return {
      land_certificate_id: getters.selectedLandCertificateId,
      certificate_number: state.certificateNumber,
      total_number_of_floor: state.totalNumberOfFloor,
      furnishing_condition_id: state.selectedFurnishCond ? state.selectedFurnishCond.id : null,
      floor: state.floor,
      unit: state.unit,
      unit_name: state.unitName,
      // amounts:getters.facilityAmountFormData,
      listing_facilities: tempFacility,
      // attributes:getters.selectedFacilityIds,
      other_facilities: filteredOtherFacilities,
      shared_facilities: filteredSharedFacilities,
    };
  },
};

export const mutations = {
  SET_FURNISH_COND_LIST(state, payload) {
    state.furnishingConditionList = payload;
  },

  RESTORE_INITIAL_STATE(state) {
    state.landCertificateList = [];
    state.furnishingConditionList = [];
    state.certificateNumber = '';
    state.totalNumberOfFloor = '';
    state.floor = '';
    state.unit = '';
    state.unitName = '';
    state.otherFacilities = [''];
    state.sharedFacilities = [''];
    state.facilityList = [];
    state.facilityRules = null;
    state.selectedFurnishCond = null;
    state.defaultAddState = {};
  },
  SET_FACILITY_RULES(state, payload) {
    state.facilityRules = payload;
  },
  REMOVE_OTHER_FACILITY(state, index) {
    if (state.otherFacilities.length > 1) {
      state.otherFacilities.splice(index, 1);
    }
  },
  ADD_OTHER_FACILITY(state) {
    if (state.otherFacilities.length < 10) {
      state.otherFacilities.push('');
    }
  },

  REMOVE_SHARED_FACILITY(state, index) {
    if (state.sharedFacilities.length > 1) {
      state.sharedFacilities.splice(index, 1);
    }
  },
  ADD_SHARED_FACILITY(state) {
    if (state.sharedFacilities.length < 10) {
      state.sharedFacilities.push('');
    }
  },

  SET_SELECTED_FURNISH_COND(state, newVal) {
    state.selectedFurnishCond = newVal;
  },
  INPUT_OTHER_FACILITY(state, payload) {
    state.otherFacilities.splice(payload.index, 1, payload.input);
  },

  INPUT_SHARED_FACILITY(state, payload) {
    state.sharedFacilities.splice(payload.index, 1, payload.input);
  },

  SET_CERTIFICATE_NUMBER(state, newVal) {
    state.certificateNumber = newVal;
  },

  SET_TOTAL_NUMBER_OF_FLOOR(state, newVal) {
    state.totalNumberOfFloor = newVal;
  },
  SET_FLOOR(state, newVal) {
    state.floor = newVal;
  },
  SET_UNIT(state, newVal) {
    state.unit = newVal;
  },

  SET_UNIT_NAME(state, newVal) {
    state.unitName = newVal;
  },

  SET_LAND_CERTIFICATE_LIST(state, payload) {
    state.landCertificateList = payload.map(function(item) {
      item.selected = false;
      return item;
    });
  },
  SET_SELECTED_LAND_CERTIFICATE(state, landCertificateId) {
    state.landCertificateList.forEach(function(landCertificate, index_, arr_) {
      if (landCertificate.id == landCertificateId) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },

  SHOW_ALLOWED_FACILITIES(state, allowedIds) {
    if (Object.keys(state.facilityList).length > 0) {
      for (let i = 0; i < state.facilityList.length; i++) {
        if (allowedIds.indexOf(state.facilityList[i].id) !== -1) {
          state.facilityList[i].allowed = true;
        } else {
          state.facilityList[i].allowed = false;
        }
      }
    }
  },
  SET_FACILITY_LIST(state, payload) {
    state.facilityList = payload.map(function(item) {
      item.checked = false;
      item.allowed = true;
      if (item.unitName !== null) {
        item.amount = null;
      }
      return item;
    });
  },
  TOGGLE_CHECK_FACILITY(state, facilityId) {
    const facility = state.facilityList.find(function(facility) {
      return facility.id == facilityId;
    });
    facility.checked = !facility.checked;

    if (!facility.checked) {
      if (facility.unitName !== null) {
        facility.amount = null;
      }
    }
  },
  SET_SELECTED_FACILITY_RADIO(state, facilityId) {
    const includedId = [26, 27, 28];
    const oldSelectedRadio = state.facilityList.find(element => {
      if (includedId.includes(parseInt(element.id))) {
        return element.checked;
      }
      return false;
    });
    if (oldSelectedRadio) {
      oldSelectedRadio.checked = false;
    }

    const newSelectedRadio = state.facilityList.find(elem => {
      return elem.id == facilityId;
    });
    newSelectedRadio.checked = true;
  },
  CHANGE_FACILITY_AMOUNT(state, { attributeId, amount }) {
    const facility = state.facilityList.find(function(fac) {
      return fac.id == attributeId;
    });
    facility.amount = amount;
  },
  SET_OTHER_FACILITIES(state, otherFacilities) {
    let temp = [];
    otherFacilities.forEach(function(element) {
      temp.push(element);
    });
    state.otherFacilities = temp;
  },
  SET_SHARED_FACILITIES(state, sharedFacilities) {
    let temp = [];
    sharedFacilities.forEach(function(element) {
      temp.push(element);
    });
    state.sharedFacilities = temp;
  },

  SET_DEFAULT_ADD_STATE(state) {
    const currentState = JSON.parse(
      JSON.stringify({
        landCertificateList: state.landCertificateList,
        certificateNumber: state.certificateNumber,
        totalNumberOfFloor: state.totalNumberOfFloor,
        floor: state.floor,
        unit: state.unit,
        unitName: state.unitName,
        otherFacilities: state.otherFacilities,
        sharedFacilities: state.sharedFacilities,
        facilityList: state.facilityList,
        facilityRules: state.facilityRules,
        furnishingConditionList: state.furnishingConditionList,
        selectedFurnishCond: state.selectedFurnishCond,
      }),
    );
    state.defaultAddState = currentState;
  },
  RESTORE_DEFAULT_ADD_STATE(state) {
    const defaultVal = JSON.parse(JSON.stringify(state.defaultAddState));
    (state.landCertificateList = defaultVal.landCertificateList),
      (state.certificateNumber = defaultVal.certificateNumber),
      (state.totalNumberOfFloor = defaultVal.totalNumberOfFloor),
      (state.floor = defaultVal.floor),
      (state.unit = defaultVal.unit),
      (state.unitName = defaultVal.unitName),
      (state.otherFacilities = defaultVal.otherFacilities),
      (state.sharedFacilities = defaultVal.sharedFacilities),
      (state.facilityList = defaultVal.facilityList),
      (state.facilityRules = defaultVal.facilityRules),
      (state.furnishingConditionList = defaultVal.furnishingConditionList),
      (state.selectedFurnishCond = defaultVal.selectedFurnishCond);
  },
};

export const actions = {
  applyFacilityRule({ commit, state }, propertyTypeId) {
    const allowedRules = state.facilityRules
      .filter(rule => {
        return rule.property_type_id === propertyTypeId;
      })
      .map(rule => {
        return rule.attribute_id;
      });
    commit('SHOW_ALLOWED_FACILITIES', allowedRules);
  },

  applyEditData({ commit, state }, { data }) {
    commit('SET_SELECTED_LAND_CERTIFICATE', data.land_certificate_id);
    commit('SET_CERTIFICATE_NUMBER', data.certificate_number);
    commit('SET_TOTAL_NUMBER_OF_FLOOR', data.total_number_of_floor);
    commit('SET_FLOOR', data.floor);
    commit('SET_UNIT', data.unit);
    commit('SET_UNIT_NAME', data.unit_name);
    commit('SET_OTHER_FACILITIES', data.other_facilities);
    commit('SET_SHARED_FACILITIES', data.shared_facilities);
    for (let key in data.listing_facilities) {
      // The key is key
      // The value is obj[key]
      commit('TOGGLE_CHECK_FACILITY', key);
      if (data.listing_facilities[key] != null) {
        commit('CHANGE_FACILITY_AMOUNT', {
          attributeId: key,
          amount: parseInt(data.listing_facilities[key]),
        });
      }
    }
    if (data.furnishing_condition_id) {
      let selectedFurnishCond = state.furnishingConditionList.find(function(item) {
        return item.id == data.furnishing_condition_id;
      });
      if (!selectedFurnishCond) {
        selectedFurnishCond = null;
      }
      commit('SET_SELECTED_FURNISH_COND', selectedFurnishCond);
    }
  },

  async applyBaseData({ commit }, { data }) {
    commit('SET_FACILITY_LIST', data.attributes);
    commit('SET_LAND_CERTIFICATE_LIST', data.landCertificates);
    commit('SET_FACILITY_RULES', data.attributeRule);
    commit('SET_FURNISH_COND_LIST', data.furnishing_conditions);
  },
};
