export const state = () => ({
  fullName: null,
  ktp: null,
  phone: null,
  phoneCountryCode: null,
  address: null,
  email: null,
  npwp: null,
  npwpPhotoUuid: null,
  ktpPhotoUuid: null,
  initRegionalData: null,
  newPrice: null,
  offerLetter: null,
  offerLetterType: 'default',
  clauses: [''],
});

export const getters = {
  formData(state) {
    return {
      full_name: state.fullName,
      ktp: state.ktp,
      phone: state.phone,
      phone_country_code: state.phoneCountryCode,
      email: state.email,
      new_price: state.newPrice,
      // address: state.address,
      // npwp: state.npwp,
      // npwp_photo_uuid: state.npwpPhotoUuid,
      ktp_photo_uuid: state.ktpPhotoUuid,
      offer_letter_uuid: state.offerLetter ? state.offerLetter.uuid : null,
      clauses: state.clauses.length === 1 && !state.clauses[0] ? [] : state.clauses,
    };
  },
};
export const mutations = {
  SET_FULL_NAME(state, payload) {
    state.fullName = payload;
  },
  SET_INIT_REGIONAL_DATA(state, payload) {
    state.initRegionalData = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
  SET_PHONE_COUNTRY_CODE(state, payload) {
    state.phoneCountryCode = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_NPWP(state, payload) {
    state.npwp = payload;
  },
  SET_NPWP_PHOTO_UUID(state, payload) {
    state.npwpPhotoUuid = payload;
  },
  SET_KTP(state, payload) {
    state.ktp = payload;
  },
  SET_KTP_PHOTO_UUID(state, payload) {
    state.ktpPhotoUuid = payload;
  },
  SET_NEW_PRICE(state, payload) {
    state.newPrice = payload;
  },
  SET_OFFER_LETTER(state, payload) {
    state.offerLetter = payload;
  },
  SET_OFFER_LETTER_TYPE(state, payload) {
    state.offerLetterType = payload;
  },
  SET_CLAUSES(state, payload) {
    state.clauses = payload;
  },
  ADD_CLAUSE(state) {
    if (state.clauses.length < 10) {
      state.clauses.push('');
    }
  },
  REMOVE_CLAUSE(state, index) {
    if (state.clauses.length > 1) {
      state.clauses.splice(index, 1);
    }
  },
  INPUT_CLAUSE(state, payload) {
    state.clauses.splice(payload.index, 1, payload.input);
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_FULL_NAME', null);
    commit('SET_PHONE', null);
    commit('SET_ADDRESS', null);
    commit('SET_EMAIL', null);
    commit('SET_NPWP', null);
    commit('SET_NPWP_PHOTO_UUID', null);
    commit('SET_KTP', null);
    commit('SET_KTP_PHOTO_UUID', null);
    commit('SET_INIT_REGIONAL_DATA', null);
    commit('SET_NEW_PRICE', null);
    commit('SET_OFFER_LETTER', null);
    commit('SET_OFFER_LETTER_TYPE', 'default');
    commit('SET_CLAUSES', ['']);
  },

  applyGetData({ commit }, data) {
    commit('SET_FULL_NAME', data.full_name);
    commit('SET_PHONE', data.phone);
    commit('SET_ADDRESS', data.address);
    commit('SET_EMAIL', data.email);
    commit('SET_NPWP', data.npwp);
    commit('SET_KTP', data.identity_card_number);
    commit('SET_INIT_REGIONAL_DATA', data.regional_data);
    commit('SET_NEW_PRICE', data.new_price);
  },

  // eslint-disable-next-line no-unused-vars
  async previewOfferLetter({ commit, state, getters }, { listingUuid }) {
    try {
      // commit('SET_IS_LOADING', true);
      let formData = JSON.parse(JSON.stringify(getters['formData']));
      formData.listing_uuid = listingUuid;
      const { data } = await this.$http.get('api/v2/sell_transaction/get_offer_letter_template', {
        params: formData,
      });
      return data.data;
    } finally {
      // commit('SET_IS_LOADING', false);
    }
  },
};
