import { CancelToken } from 'axios';

export const state = () => ({
  listingType: 'R',
  categoryNavList: [
    { name: 'active', isActive: true, count: 0 },
    { name: 'draft', isActive: false, count: 0 },
    { name: 'notActive', isActive: false, count: 0 },
    { name: 'occupied', isActive: false, count: 0 },
  ],
  sortByNavList: [
    { name: 'newest', isActive: true, data: 1 },
    { name: 'oldest', isActive: false, data: 2 },
    { name: 'highestPrice', isActive: false, data: 3 },
    { name: 'lowestPrice', isActive: false, data: 4 },
  ],
  listingItemList: [],
  metaPagination: {},
  isFetchingListing: true,
  actionLoading: false,
  searchListingOptions: [],
  currentKeyword: '',
  getMyListingToken: null,
  prevPage: '',
  activeSidebar: true,
});

export const getters = {
  allowedCategory(state) {
    // if (state.listingType === 'S') {
    return state.categoryNavList;
    // }
    // return state.categoryNavList.filter(item => item.name !== 'sold');
  },
  keywordText(state) {
    if (state.currentKeyword) {
      return state.currentKeyword.text;
    } else {
      return '';
    }
  },
  keywordId(state) {
    if (state.currentKeyword) {
      return state.currentKeyword.id;
    } else {
      return '';
    }
  },
  getCategory: state => index => {
    return state.categoryNavList[index];
  },
  getSortBy: state => index => {
    return state.sortByNavList[index];
  },
  activeCategory(state) {
    return state.categoryNavList.find(category => {
      return category.isActive;
    }).name;
  },
  activeCategoryObject(state) {
    return state.categoryNavList.find(category => {
      return category.isActive;
    });
  },
  activeSortBy(state) {
    return state.sortByNavList.find(function(sortBy) {
      return sortBy.isActive === true;
    });
  },
  getListing: state => index => {
    return state.listingItemList[index];
  },
};

export const mutations = {
  SET_LISTING_TYPE(state, payload) {
    state.listingType = payload;
  },
  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },
  SET_GET_MY_LISTING_TOKEN(state, payload) {
    state.getMyListingToken = payload;
  },
  SET_META_PAGINATION(state, meta) {
    state.metaPagination = meta;
  },
  SET_CURRENT_KEYWORD(state, keyword) {
    state.currentKeyword = keyword;
  },
  SET_ACTIVE_CATEGORY(state, selectedCategoryName) {
    state.categoryNavList.forEach(function(categoryNav_) {
      categoryNav_.isActive = categoryNav_.name === selectedCategoryName;
    });
  },
  SET_ACTIVE_SORT_BY(state, selectedSortByName) {
    state.sortByNavList.forEach(function(sortByNav_) {
      sortByNav_.isActive = sortByNav_.name === selectedSortByName;
    });
  },
  SET_LOADING_TRUE(state) {
    state.isFetchingListing = true;
  },
  SET_LOADING_FALSE(state) {
    state.isFetchingListing = false;
  },
  SET_LISTING_ITEM_LIST(state, listingItemList) {
    state.listingItemList = listingItemList;
  },
  SET_ACTIVE_SIDEBAR(state, payload) {
    state.activeSidebar = payload;
  },
  UPDATE_CATEGORY_COUNT(state, payload) {
    const category = state.categoryNavList.find(function(category) {
      return category.name === payload.categoryName;
    });
    category.count = payload.count;
    // state.listingItemList = listingItemList;
  },
  RESET_LISTING_ITEMS(state) {
    state.listingItemList = [];
  },
  UPDATE_SEARCH_OPTIONS(state, options) {
    state.searchListingOptions = options.filter(option => {
      return option.id !== '' && option.id != null;
    });
  },
  RESTORE_INITIAL_STATE(state) {
    state.categoryNavList = [
      { name: 'active', isActive: true, count: 0 },
      { name: 'draft', isActive: false, count: 0 },
      { name: 'notActive', isActive: false, count: 0 },
      { name: 'occupied', isActive: false, count: 0 },
    ];
    state.sortByNavList = [
      { name: 'newest', isActive: true, data: 1 },
      { name: 'oldest', isActive: false, data: 2 },
      { name: 'highestPrice', isActive: false, data: 3 },
      { name: 'lowestPrice', isActive: false, data: 4 },
    ];
    state.listingItemList = [];
    state.metaPagination = {};
    state.isFetchingListing = true;
    state.actionLoading = false;
    state.searchListingOptions = [];
    state.currentKeyword = '';
    state.getMyListingToken = null;
  },
};

export const actions = {
  changeActiveCategory({ commit, dispatch }, selectedCategoryName) {
    commit('SET_CURRENT_KEYWORD', '');
    commit('SET_ACTIVE_CATEGORY', selectedCategoryName);
    dispatch('getListings');
    dispatch('fetchSearchListingOptions');
    dispatch('getCategoryCountData');
  },
  changeListingType({ commit, dispatch }) {
    commit('SET_CURRENT_KEYWORD', '');
    commit('SET_ACTIVE_CATEGORY', 'active');
    dispatch('getListings');
    dispatch('fetchSearchListingOptions');
    dispatch('getCategoryCountData');
  },
  changeActiveSortBy({ commit, dispatch }, selectedSortByName) {
    commit('SET_ACTIVE_SORT_BY', selectedSortByName);
    dispatch('getListings', this.prevPage);
    dispatch('getCategoryCountData');
  },
  getCategoryCountData({ commit, state }) {
    this.$http
      .get('/api/v1/mylisting/getTotalActiveListing', {
        params: {
          listing_type: state.listingType,
        },
      })
      .then(response => {
        commit('UPDATE_CATEGORY_COUNT', {
          categoryName: 'active',
          count: response.data,
        });
      });
    this.$http
      .get('/api/v1/mylisting/getTotalNotActiveListing', {
        params: {
          listing_type: state.listingType,
        },
      })
      .then(response => {
        commit('UPDATE_CATEGORY_COUNT', {
          categoryName: 'notActive',
          count: response.data,
        });
      });
    this.$http
      .get('/api/v1/mylisting/getTotalDraftListing', {
        params: {
          listing_type: state.listingType,
        },
      })
      .then(response => {
        commit('UPDATE_CATEGORY_COUNT', {
          categoryName: 'draft',
          count: response.data,
        });
      });
    // this.$http
    //   .get('/api/v1/mylisting/getTotalSoldListing', {
    //     params: {
    //       listing_type: state.listingType,
    //     },
    //   })
    //   .then(response => {
    //     commit('UPDATE_CATEGORY_COUNT', {
    //       categoryName: 'sold',
    //       count: response.data,
    //     });
    //   });
  },
  fetchSearchListingOptions({ getters, commit, state }) {
    return new Promise(async resolve => {
      const response = await this.$http.get('/api/v1/getMyListing/getMyListingSearchData', {
        params: {
          category: getters.activeCategory,
          listing_type: state.listingType,
        },
      });
      commit('UPDATE_SEARCH_OPTIONS', response.data.data);
      resolve();
    });
  },
  async getListings({ commit, dispatch }, page) {
    commit('RESET_LISTING_ITEMS');
    commit('SET_LOADING_TRUE');
    if (page === undefined) {
      page = 1;
    }
    this.prevPage = page;
    const response = await dispatch('fetchListingRequest', page);
    commit('SET_LISTING_ITEM_LIST', response.data.data);
    commit('SET_META_PAGINATION', response.data.meta);
    commit('SET_LOADING_FALSE');
  },
  fetchListingRequest({ state, commit, getters }, page) {
    if (state.getMyListingToken) {
      state.getMyListingToken.cancel('Request canceled.');
    }
    commit('SET_GET_MY_LISTING_TOKEN', CancelToken.source());
    return this.$http.get(
      '/api/v2/mylisting/get_listings/' + getters.activeCategory + '/' + getters.activeSortBy.data,
      {
        cancelToken: state.getMyListingToken.token,
        params: {
          keyword: getters.keywordId,
          page: page,
          listing_type: state.listingType,
        },
      },
    );
  },
  // eslint-disable-next-line no-empty-pattern
  async notOccupiedListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/not_occupied', {
      listingUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async occupiedListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/occupied', {
      listingUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async deactivateListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/not_active', {
      listingUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async duplicateListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/duplicate', {
      listing_uuid: listingUuid,
    });
    return data.data.uuid;
  },
  // eslint-disable-next-line no-empty-pattern
  async activateListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/active', {
      listingUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async checkPublishListing({}, listingUuid) {
    const { data } = await this.$http.get('/api/v2/mylisting/check_publish', {
      params: {
        listingUuid,
      },
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async publishListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/publish', {
      listingUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async checkDeleteListing({}, listingUuid) {
    const { data } = await this.$http.get('/api/v2/mylisting/check_delete_listing', {
      params: {
        listingUuid,
      },
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/delete', {
      listingUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async repostListing({}, listingUuid) {
    const { data } = await this.$http.post('/api/v2/mylisting/set_listing_status/repost', {
      listingUuid,
    });
    return data.data;
  },
};
