export const state = () => ({
  propertyTypes: [],
  propertyTypeCategories: [],
  rentTypes: [],
  rentPeriods: [],
  selectedPropertyTypes: [],
  rentType: {},
  listingPartner: [],
  propertyPartner: [],
  tenureTypes: [],
  districts: [],
  residentialTypesRent: [],
  commercialTypesRent: [],
  residentialTypesSell: [],
  commercialTypesSell: [],
});

export const getters = {
  propertyTypesByCategory(state, getters, rootState) {
    let propertyTypeCategory = rootState.v2.search.selectedPropertyTypeCategory;
    if (propertyTypeCategory) {
      return state.propertyTypes.filter(function(propertyType) {
        return propertyType.property_type_category_id === propertyTypeCategory.id;
      });
    } else {
      return state.propertyTypes;
    }
  },
  selectedPropertyTypesString(state) {
    if (state.selectedPropertyTypes && state.selectedPropertyTypes.length > 0) {
      let text = '';
      if (state.selectedPropertyTypes.length >= 1) {
        text += state.selectedPropertyTypes[0];
      }
      if (state.selectedPropertyTypes.length >= 2) {
        text += ', ' + state.selectedPropertyTypes[1];
      }
      if (state.selectedPropertyTypes.length >= 3) {
        text += ' and More';
      }
      return text;
    } else {
      return 'Commercial, Industrial & Residential Properties';
    }
  },
};

export const mutations = {
  set_property_types(state, payload) {
    state.propertyTypes = payload;
  },
  SET_PROPERTY_TYPE_CATEGORIES(state, payload) {
    state.propertyTypeCategories = payload;
  },
  SET_SELECTED_PROPERTY_TYPES(state, payload) {
    state.selectedPropertyTypes = payload;
  },
  set_rent_types(state, payload) {
    state.rentTypes = payload;
  },
  set_rent_type(state, payload) {
    state.rentType = payload;
  },
  set_rent_periods(state, payload) {
    state.rentPeriods = payload;
  },
  set_listing_partner(state, payload) {
    state.listingPartner = payload;
  },
  set_property_partner(state, payload) {
    state.propertyPartner = payload;
  },
  set_tenure_types(state, payload) {
    if (payload)
      payload.unshift({
        id: null,
        name: 'general.filter.allTenures',
      });
    state.tenureTypes = payload;
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_RESIDENTIAL_TYPES_RENT(state, payload) {
    state.residentialTypesRent = payload;
  },
  SET_COMMERCIAL_TYPES_RENT(state, payload) {
    state.commercialTypesRent = payload;
  },
  SET_RESIDENTIAL_TYPES_SELL(state, payload) {
    state.residentialTypesSell = payload;
  },
  SET_COMMERCIAL_TYPES_SELL(state, payload) {
    state.commercialTypesSell = payload;
  },
};

export const actions = {
  async getPropertyTypes({ commit, rootState }, { group: group, listingType: listingType }) {
    await this.$http
      .get('/api/v2/master/property-types', {
        params: {
          group: group,
          listing_type: listingType ? listingType : rootState.v2.search.listingType,
        },
      })
      .then(response => {
        commit('set_property_types', response.data.data);
      });
  },

  async getPropertyTypesByCategory({ commit, rootState }, categoryId) {
    await this.$http
      .get('/api/v2/master/property-types', {
        params: {
          category_group_id: categoryId && !isNaN(categoryId) ? categoryId : null,
          listing_type: rootState.v2.search.listingType,
        },
      })
      .then(response => {
        commit('set_property_types', response.data.data);
      });
  },

  async getPropertyTypeCategories({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/property-type-categories', {
        params: {
          category_group_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then(response => {
        commit('SET_PROPERTY_TYPE_CATEGORIES', response.data.data);
      });
  },

  async getPropertyTypesHeader(
    // eslint-disable-next-line no-unused-vars
    { commit },
    { category_group_id: categoryGroupId, listing_type: listingType },
  ) {
    let response = await this.$http.get('/api/v2/master/property-types', {
      params: {
        category_group_id: categoryGroupId,
        listing_type: listingType,
      },
    });
    return response.data.data;
  },

  // eslint-disable-next-line
  async getPropertyTypeByName({ commit }, propertyTypeName) {
    let response = await this.$http.get('/api/v2/master/property-type/' + propertyTypeName);

    return response.data.data;
  },
  async getRentTypes({ commit }) {
    await this.$http.get('/api/v2/master/rent-types').then(result => {
      commit('set_rent_types', result.data.data);
    });
  },

  async getRentTypesByCategory({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/rent-types', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then(response => {
        commit('set_rent_types', response.data.data);
      });
  },

  async getRentTypeById({ commit }, id) {
    await this.$http.get('/api/v2/master/' + id + '/rent-type').then(response => {
      commit('set_rent_type', response.data.data);
    });
  },
  async getRentPeriods({ commit }) {
    await this.$http.get('/api/v2/master/rent-periods').then(result => {
      commit('set_rent_periods', result.data.data);
    });
  },

  async getRentPeriodsByCategory({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/rent-periods', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then(response => {
        commit('set_rent_periods', response.data.data);
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async getCitiesByProvinceId({}, province_id) {
    let response = await this.$http.get('/api/v2/regional/get-cities-by-province', {
      params: {
        province_id: province_id,
      },
    });

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getDistrictsByCityId({}, city_id) {
    let response = await this.$http.get('/api/v2/regional/get-districts-by-city', {
      params: {
        city_id: city_id,
      },
    });

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getVillagesByDistrictId({}, district_id) {
    let response = await this.$http.get('/api/v2/regional/get-villages-by-district', {
      params: {
        district_id: district_id,
      },
    });

    return response.data.data;
  },
  async getListingPartner({ commit }) {
    let response = await this.$http.get('/api/v2/master/listing-partner');

    commit('set_listing_partner', response.data.data);
  },
  async getPropertyPartner({ commit }) {
    let response = await this.$http.get('/api/v2/master/property-partner');

    commit('set_property_partner', response.data.data);
  },
  async getTenureTypes({ commit }) {
    let response = await this.$http.get('/api/v2/master/tenure-types');

    commit('set_tenure_types', response.data.data);
  },
  // eslint-disable-next-line no-unused-vars
  async getDistricts({ commit, rootState }, { listingType: listingType }) {
    await this.$http
      .get('/api/v2/master/districts', {
        params: {
          // listing_type: listingType ? listingType : rootState.v2.search.listingType,
        },
      })
      .then(response => {
        commit('SET_DISTRICTS', response.data.data);
      });
  },
};
