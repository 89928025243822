export const state = () => ({
  isLoading: false,
  uuid: null,
  ownerName: null,
  ownerKtp: null,
  agentName: null,
  agentCeaNumber: null,
  selectedBankType: null,
  lawPracticeName: null,
  bankName: null,
  bankAccountNumber: null,
  lawFirmName: null,
  solicitorName: null,
});

export const getters = {
  formData(state) {
    return {
      uuid: state.uuid,
      owner_name: state.ownerName,
      owner_ktp: state.ownerKtp,
      agent_name: state.agentName,
      agent_cea_number: state.agentCeaNumber,
      law_practice_name: state.lawPracticeName,
      bank_name: state.bankName,
      bank_account_number: state.bankAccountNumber,
      law_firm_name: state.lawFirmName,
      solicitor_name: state.solicitorName,
    };
  },
};

export const mutations = {
  SET_SELECTED_BANK_TYPE(state, payload) {
    state.selectedBankType = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_OWNER_NAME(state, payload) {
    state.ownerName = payload;
  },
  SET_OWNER_KTP(state, payload) {
    state.ownerKtp = payload;
  },
  SET_AGENT_NAME(state, payload) {
    state.agentName = payload;
  },
  SET_AGENT_CEA_NUMBER(state, payload) {
    state.agentCeaNumber = payload;
  },
  SET_LAW_PRACTICE_NAME(state, payload) {
    state.lawPracticeName = payload;
  },
  SET_BANK_NAME(state, payload) {
    state.bankName = payload;
  },
  SET_BANK_ACCOUNT_NUMBER(state, payload) {
    state.bankAccountNumber = payload;
  },
  SET_LAW_FIRM_NAME(state, payload) {
    state.lawFirmName = payload;
  },
  SET_SOLICITOR_NAME(state, payload) {
    state.solicitorName = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_SELECTED_BANK_TYPE', null);
    commit('SET_UUID', null);
    commit('SET_OWNER_NAME', null);
    commit('SET_OWNER_KTP', null);
    commit('SET_AGENT_NAME', null);
    commit('SET_AGENT_CEA_NUMBER', null);
    commit('SET_BANK_NAME', null);
    commit('SET_BANK_ACCOUNT_NUMBER', null);
    commit('SET_LAW_FIRM_NAME', null);
    commit('SET_SOLICITOR_NAME', null);
  },

  // eslint-disable-next-line no-unused-vars
  async postAcceptBooking({ commit, getters }) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$http.post(
        'api/v2/sell_transaction/owner_accept',
        getters.formData,
      );
      console.log('RESPONSE postAccept: ', data.data);

      return data.data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
