export const state = () => ({
  provinces: [],
  province: null,
  cities: [],
  city: null,
  districts: [],
  district: null,
  villages: [],
  village: null,
});

export const getters = {
  getProvinceById: state => id => {
    return state.provinces.find(province => {
      return province.id === id;
    });
  },
  provinceId(state) {
    return state.provinces.map(function($item) {
      return $item.id;
    });
  },
  provinceIdLabel: state => id => {
    const item = state.provinces.find(function(item) {
      return item.id === id;
    });
    return item ? item.text : null;
  },
  getCityById: state => id => {
    if (state.cities) {
      return state.cities.find(city => {
        return city.id === id;
      });
    }
  },
  cityId(state) {
    if (state.cities) {
      return state.cities.map(function($item) {
        return $item.id;
      });
    }
  },
  cityIdLabel: state => id => {
    const item = state.cities.find(function(item) {
      return item.id === id;
    });
    return item ? item.text : null;
  },
  getDistrictById: state => id => {
    return state.districts.find(district => {
      return district.id === id;
    });
  },
  districtId(state) {
    if (state.districts) {
      return state.districts.map(function($item) {
        return $item.id;
      });
    }
  },
  districtIdLabel: state => id => {
    if (state.districts) {
      const item = state.districts.find(function(item) {
        return item.id === id;
      });
      return item ? item.text : null;
    }
  },
  getVillageById: state => id => {
    return state.villages.find(village => {
      return village.id === id;
    });
  },
  villageId(state) {
    if (state.villages) {
      return state.villages.map(function($item) {
        return $item.id;
      });
    }
  },
  villageIdLabel: state => id => {
    if (state.villages) {
      const item = state.villages.find(function(item) {
        return item.id === id;
      });
      return item ? item.text : null;
    }
  },
};

export const mutations = {
  SET_PROVINCE(state, payload) {
    state.province = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_DISTRICT(state, payload) {
    state.district = payload;
  },
  SET_VILLAGE(state, payload) {
    state.village = payload;
  },
  SET_PROVINCES(state, payload) {
    state.provinces = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_VILLAGES(state, payload) {
    state.villages = payload;
  },
  RESET_REGIONAL(state) {
    state.province = null;
    state.cities = [];
    state.city = null;
    state.districts = [];
    state.district = null;
    state.villages = [];
    state.village = null;
  },
};

export const actions = {
  async setRegionalData({ commit, dispatch }, requestData) {
    await commit('SET_PROVINCES', requestData.provinces);
    await commit('SET_CITIES', requestData.cities ? requestData.cities : []);
    await commit('SET_DISTRICTS', requestData.districts ? requestData.districts : []);
    await commit('SET_VILLAGES', requestData.villages ? requestData.villages : []);

    dispatch('changeProvince', requestData.province);
    dispatch('changeCity', requestData.city);
    dispatch('changeDistrict', requestData.district);
    dispatch('changeVillage', requestData.village);
  },

  changeProvince({ commit, state }, pId) {
    commit('SET_PROVINCE', pId);
    // eslint-disable-next-line no-unused-vars
    const selectedProvince = state.provinces.find(function(item) {
      return item.id == pId;
    });
  },
  changeCity({ commit, state }, cId) {
    if (cId) {
      commit('SET_CITY', cId);
      // eslint-disable-next-line no-unused-vars
      const selectedCity = state.cities.find(function(item) {
        return item.id == cId;
      });
    }
  },
  changeDistrict({ commit, state }, dId) {
    if (dId) {
      commit('SET_DISTRICT', dId);
      // eslint-disable-next-line no-unused-vars
      const selectedDistrict = state.districts.find(function(item) {
        return item.id == dId;
      });
    }
  },
  changeVillage({ commit, state }, vId) {
    if (vId) {
      commit('SET_VILLAGE', vId);
      // eslint-disable-next-line no-unused-vars
      const selectedVillage = state.villages.find(function(item) {
        return item.id == vId;
      });
    }
  },
  async getCities({ commit, dispatch }, provinceId) {
    await commit('SET_CITIES', []);
    await commit('SET_DISTRICTS', []);
    await commit('SET_VILLAGES', []);
    await dispatch('changeCity', null);
    await dispatch('changeDistrict', null);
    await dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getCityByProvince', {
        province_id: provinceId,
      })
      .then(result => {
        commit('SET_CITIES', result.data);
      });
  },
  async getDistricts({ commit, dispatch }, cityId) {
    commit('SET_DISTRICTS', []);
    commit('SET_VILLAGES', []);
    dispatch('changeDistrict', null);
    dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getDistrictByCity', {
        city_id: cityId,
      })
      .then(result => {
        commit('SET_DISTRICTS', result.data);
      });
  },
  async getVillages({ commit, dispatch }, districtId) {
    commit('SET_VILLAGES', []);
    dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getVillageByDistrict', {
        district_id: districtId,
      })
      .then(result => {
        commit('SET_VILLAGES', result.data);
      });
  },
  async getProvinces({ commit }) {
    await this.$http.get('/api/v1/getAllProvince').then(result => {
      commit('SET_PROVINCES', result.data);
    });
  },
};
