export const state = () => ({
  listing: {},
  imageIndex: null,
  floorPlanIndex: null,
  listings: {},
  meta: {},
  newListings: [],
  partialRentListings: [],
  premierListings: [],
  residentialListings: [],
  commercialListings: [],
  stayListings: [],
  businessListings: [],
  inventoryListings: [],
  embed360Listings: [],
  projectPartnerListings: [],
  discountListings: [],
  compareListings: [],
  compareShow: false,
});

export const getters = {
  galleries(state) {
    if (Object.keys(state.listing).length > 0) {
      let clonedPhotos = JSON.parse(JSON.stringify(state.listing.photos));
      clonedPhotos.sort(function(a, b) {
        if (a.is_primary_photo === true) return -1;
        else if (b.is_primary_photo === true) return 1;
        else return 0;
      });

      let photos = [];
      for (let photo of clonedPhotos) {
        photos.push({
          url: photo.url,
          alt: state.listing.listing_title + ' - Propfix',
        });
      }

      return photos;
    }
  },

  floorPlanGalleries(state) {
    if (Object.keys(state.listing).length > 0) {
      if (state.listing.floor_plans) {
        let photos = [];
        for (let photo of state.listing.floor_plans) {
          photos.push({
            url: photo.url,
            alt: 'Floor Plan ' + state.listing.listing_title + ' - Propfix',
          });
        }
        return photos;
      }
    }
  },
  firstRentPrice(state) {
    if (Object.keys(state.listing).length > 0) {
      return state.listing.rent_prices[0];
    }
  },
  listings(state) {
    return state.listings;
  },
  compareShow(state) {
    return state.compareShow;
  },
};

export const mutations = {
  set_listing(state, payload) {
    state.listing = payload;
  },
  set_image_index(state, index) {
    state.imageIndex = index;
  },
  set_floor_plan_index(state, index) {
    state.floorPlanIndex = index;
  },
  set_listings(state, { data, meta }) {
    state.listings = data;
    state.meta = meta;
  },
  set_new_listings(state, listings) {
    state.newListings = listings;
  },
  set_premier_listings(state, listings) {
    state.premierListings = listings;
  },
  set_partial_rent_listings(state, listings) {
    state.partialRentListings = listings;
  },
  SET_PROJECT_PARTNER_LISTINGS(state, listings) {
    state.projectPartnerListings = listings;
  },
  SET_DISCOUNT_LISTINGS(state, listings) {
    state.discountListings = listings;
  },
  SET_RESIDENTIAL_LISTINGS(state, listings) {
    state.residentialListings = listings;
  },
  SET_COMMERCIAL_LISTINGS(state, listings) {
    state.commercialListings = listings;
  },
  SET_STAY_LISTINGS(state, listings) {
    state.stayListings = listings;
  },
  SET_BUSINESS_LISTINGS(state, listings) {
    state.businessListings = listings;
  },
  SET_INVENTORY_LISTINGS(state, listings) {
    state.inventoryListings = listings;
  },
  SET_EMBED_360_LISTINGS(state, listings) {
    state.embed360Listings = listings;
  },
  set_compare_listing(state, payload) {
    state.compareListings.push(payload);
  },
  remove_compare_listing(state, payload) {
    state.compareListings.splice(state.compareListings.indexOf(payload), 1);
  },
  reset_compare_listing(state) {
    state.compareListings = [];
  },
  set_compare_show(state, payload) {
    state.compareShow = payload;
  },
};

export const actions = {
  async getListing({ commit }, uuid) {
    try {
      let response = await this.$http.get('/api/v2/listing/' + uuid);
      console.log('RESULT GET LISTING:', response.data.data);
      commit('set_listing', response.data.data);
    } catch (e) {
      console.log('ERROR GET LISTING DETAIL: ', e);
      commit('set_listing', null);
      throw e;
    }
  },

  async getCategoryListings({ commit }, { category, listingType, device }) {
    let query = {};
    if (listingType) query.listing_type = listingType;

    query.paginate = 6;
    query.premier = true;

    let homePaginate = 3;
    if (device && device.isMobile) homePaginate = 2;
    let url = '/api/v2/search';
    switch (category) {
      case 'premier': {
        break;
      }
      case 'residential': {
        query.paginate = homePaginate;
        query.property_type_category_group_id = 2;
        delete query.premier;
        break;
      }
      case 'commercial': {
        query.paginate = homePaginate;
        query.property_type_category_group_id = 1;
        delete query.premier;
        break;
      }
      case '360': {
        query.paginate = homePaginate;
        query.pano_required = true;
        break;
      }
      case 'projectpartner': {
        query.paginate = homePaginate;
        url = '/api/v2/search/project-partners';
        break;
      }
      case 'discount': {
        query.paginate = homePaginate;
        query.discount = true;
        break;
      }
    }

    await this.$http
      .get(url, {
        params: query,
      })
      .then(response => {
        if (category === 'premier') {
          commit('set_premier_listings', response.data.data.data);
        } else if (category === 'residential') {
          commit('SET_RESIDENTIAL_LISTINGS', response.data.data.data);
        } else if (category === 'commercial') {
          commit('SET_COMMERCIAL_LISTINGS', response.data.data.data);
        } else if (category === 'stay') {
          commit('SET_STAY_LISTINGS', response.data.data.data);
        } else if (category === 'business') {
          commit('SET_BUSINESS_LISTINGS', response.data.data.data);
        } else if (category === 'inventory') {
          commit('SET_INVENTORY_LISTINGS', response.data.data.data);
        } else if (category === '360') {
          commit('SET_EMBED_360_LISTINGS', response.data.data.data);
        } else if (category === 'projectpartner') {
          commit('SET_PROJECT_PARTNER_LISTINGS', response.data.data.data);
        } else if (category === 'discount') {
          commit('SET_DISCOUNT_LISTINGS', response.data.data.data);
        } else if (category === 'new-listings') {
          commit('set_new_listings', response.data.data.data);
        } else if (category === 'partial-rent') {
          commit('set_partial_rent_listings', response.data.data.data);
        }
      });
  },
  // eslint-disable-next-line
  async addOrRemoveShortlist({}, id) {
    let response = await this.$http.post('/api/v2/listings/shortlist/add-or-remove', {
      listing_id: id,
    });

    return response.data;
  },
  getYoutubeId(_, youtubeUrl) {
    if (!youtubeUrl) return null;
    // eslint-disable-next-line no-useless-escape
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youtubeUrl.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return null;
    }
  },
  async getYoutubeEmbedUrl({ dispatch }, youtubeUrl) {
    if (youtubeUrl) {
      let youtubeId = await dispatch('getYoutubeId', youtubeUrl);
      if (youtubeId !== null) {
        return `https://www.youtube.com/embed/${youtubeId}`;
      }
      return null;
    }
    return null;
  },

  async getMyShortlists({ commit }, listingType) {
    await this.$http
      .get('/api/v2/dashboard/get_shortlist', {
        params: {
          listing_type: listingType,
        },
      })
      .then(response => {
        commit('set_listings', {
          data: response.data.data,
          meta: null,
        });
      });
  },

  async getListingFromBookingRequest({ commit }, uuid) {
    let response = await this.$http.get('/api/v2/listings/booking-request-listing?uuid=' + uuid);

    commit('set_listing', response.data.data);
  },
};
