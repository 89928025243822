const requireContext = require.context('./sect2', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  isTouched: false,

  // propertyUuid:null,
  // selectedCountry:null,
  // isTouched:false,
});

export const getters = {
  formData(state, getters) {
    const detailFormData = getters['detail/formData'];
    const operationalHourFormData = getters['availability/formData'];
    return Object.assign({}, detailFormData, operationalHourFormData);
  },
};

export const mutations = {
  SET_IS_TOUCHED(state, payload) {
    state.isTouched = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_IS_TOUCHED', false);
    commit('detail/RESTORE_INITIAL_STATE');
    commit('availability/RESTORE_INITIAL_STATE');
  },
  async applyEditData({ dispatch }, { form, editData }) {
    await dispatch('detail/applyEditData', {
      form,
      data: editData,
    });
    await dispatch('availability/applyEditData', {
      form,
      data: editData,
    });
  },
  async applyBaseData({ commit, dispatch }, { form, baseData }) {
    await dispatch('detail/applyBaseData', {
      form,
      data: baseData,
    });
    await commit('availability/SET_DEFAULT_OPEN_TIME_OPTIONS');
    // commit('location/SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUP_LIST', 1);
    // commit('location/SET_DEFAULT_ADD_STATE');
    // commit('basicInfo/SET_DEFAULT_VALUE');
  },
};
